.m-pwa-teaser {
  background-color: get-color('magenta');

  body[data-display-mode='standalone'] &,
  body[data-pwa-installed='true'] &,
  body[data-pwa-supported='false'] & {
    display: none;
  }

  // stylelint-disable
  @media (display-mode: standalone) {
    display: none;
  }
  // stylelint-enable
}
