.o-references {
  padding: get-baseline(7) get-baseline(3);

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: get-baseline(5);
    }
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      margin: 0 get-baseline(1);

      @include mq(m) {
        margin: 0 get-baseline(5);
      }
    }
  }

  &__link {
    //
  }
}
