.o-landing-stage {
  @include grid-container;

  --image-min-height: calc(100vw * 0.5625);

  grid-template-rows: minmax(var(--image-min-height), 1fr) auto auto;
  padding-bottom: get-baseline(7);
  position: relative;

  @include mq(m) {
    --image-min-height: calc(min(100vw, 1680px) * 0.19140625);

    padding-bottom: get-baseline(10);
  }

  &--show-player {
    max-height: none;

    @include mq(m) {
      --image-min-height: 1fr;
    }
  }

  &__image {
    inset: 0;
    overflow: hidden;
    position: absolute;

    &::after {
      content: "";
      background: linear-gradient(
        180deg,
        hsla(0deg, 0%, 10%, 0%),
        get-color("grey-dark")
      );
      inset: 0;
      pointer-events: none;
      position: absolute;
    }

    img {
      aspect-ratio: 9 / 16;
      object-fit: cover;
      object-position: top center;
      width: 100%;

      @include mq(m) {
        aspect-ratio: 16 / 9;
      }
    }
  }

  &--show-player &__image::after {
    background: linear-gradient(
      180deg,
      hsla(0deg, 0%, 10%, 35%),
      get-color("grey-dark")
    );
  }

  &__player-wrapper {
    @include grid-item(1, -1, "mobile");

    grid-row: 1;
    margin-bottom: get-baseline(2);
    margin-top: get-baseline(15);
    min-height: 0;
    position: relative;

    @include mq(m) {
      @include grid-item(2, -2, "desktop");

      margin: 0;
    }
  }

  &--show-player &__player-wrapper {
    @include mq(m) {
      margin-top: get-baseline(11);
      margin-bottom: get-baseline(2);
    }
  }

  &__player-container {
    margin: 0 auto;
    position: relative;
    opacity: 0;
    transition: opacity 0.2s ease;
    min-height: 55vh;
  }

  &--show-player &__player-container {
    aspect-ratio: 16 / 9;
    opacity: 1;

    @include mq($until: m) {
      aspect-ratio: auto;
    }

    @include mq(m) {
      height: 100%;
      max-height: 60vh;
      min-height: 320px;
    }

    @include mq(l) {
      min-height: 480px;
    }
  }

  &__player-close-btn {
    align-items: center;
    color: get-color("white");
    display: flex;
    position: absolute;
    right: 20px;
    top: get-baseline(-4);

    svg {
      height: get-baseline(4);
      width: get-baseline(4);
    }
  }

  &--is-live &__player-close-btn {
    display: none;
  }

  &__player {
    width: 100%;
    height: 100%;
  }

  &__play-button {
    margin: 0 auto;
    padding: get-baseline(2);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
    display: grid;
    gap: get-baseline(1);
    grid-template-rows: auto auto;
    justify-items: center;
  }

  &--show-player &__play-button {
    display: none;
  }

  &__play-button-icon {
    --circle-size: #{get-baseline(7)};
    --icon-size: #{get-baseline(4)};

    background: get-color("white");
    border-radius: 999px;
    display: grid;
    height: var(--circle-size);
    place-items: center;
    width: var(--circle-size);

    @include mq(m) {
      --circle-size: #{get-baseline(11)};
      --icon-size: #{get-baseline(7)};
    }

    svg {
      color: get-color("magenta");
      height: var(--icon-size);
      width: var(--icon-size);
    }
  }

  &__countdown-wrapper {
    @include grid-item(2, -2);

    margin-bottom: get-baseline(3);
    position: relative;

    @include mq(m) {
      margin-bottom: get-baseline(4);
    }

    @media (min-width: 1280px) {
      @include grid-item(3, -3, "desktop");
    }
  }

  &__countdown {
    display: inline-flex;
    padding: get-baseline(1) get-baseline(3) get-baseline(1) 0;
    position: relative;
    color: get-color("white");

    @include mq(m) {
      padding: get-baseline(1) get-baseline(5);
    }

    > * {
      position: relative;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0 0 0 -50px;
      background: get-color("magenta");
      border-top-right-radius: get-baseline(2);
      border-bottom-right-radius: get-baseline(2);
      transform: skewY(-1deg);

      @include mq(m) {
        left: 0;
        transform: skewY(0);
        border-top-left-radius: get-baseline(1);
        border-bottom-left-radius: get-baseline(1);
      }
    }
  }

  &--is-live &__countdown-running {
    display: none;
  }

  &__countdown-live {
    display: none;
    text-transform: uppercase;

    &::before {
      --size: 10px;

      content: "";
      display: block;
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      background: get-color("magenta");
    }
  }

  &--is-live &__countdown-live {
    display: flex;
    align-items: center;
    gap: get-baseline(1);
  }

  &__content {
    @include grid-item(2, -2);

    position: relative;

    @media (min-width: 1280px) {
      @include grid-item(4, -4, "desktop");
    }
  }

  &__headline {
    margin-bottom: get-baseline(1);

    @include mq(m) {
      margin-bottom: get-baseline(2);
    }
  }

  &__ctas {
    display: flex;
    gap: get-baseline(2);
    margin-top: get-baseline(3);
  }
}
