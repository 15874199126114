.o-page-description {
  @include grid-container;

  padding: get-baseline(5) 0;
  position: relative;

  @include mq(m) {
    padding: get-baseline(8) 0;
  }

  &__wrapper {
    @include grid-item(2, -2);

    color: get-color("white");
    position: relative;

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }
  }

  &__headline {
    display: block;
    margin-bottom: get-baseline(3);

    @include mq(m) {
      text-align: center;
    }
  }

  &__paragraph {
    margin-bottom: get-baseline(4);

    @include mq(l) {
      margin-bottom: get-baseline(5);
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin: get-baseline(-1); // Ausgleich für margin auf &__button

    @include mq(m) {
      justify-content: center;
    }
  }

  &__button {
    margin: get-baseline(1);
  }
}
