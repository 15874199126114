.m-event-teaser {
  --column-width: calc((100% - 5 * #{get-baseline(3)}) / 6);

  z-index: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: get-baseline(3);
  grid-row-gap: get-baseline(4);

  &__countdown,
  &__live-headline {
    display: flex;
    z-index: 1;
    grid-column: 1 / span 6;
    justify-self: end;
    margin-bottom: get-baseline(-4);
  }

  &--is-live &__countdown {
    display: none;
  }

  &__live-headline {
    display: none;
  }

  &--is-live &__live-headline {
    display: flex;
  }

  &__live-indicator {
    @keyframes blinker {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(get-color("magenta"), 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 0, 68, 0%);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 68, 0%);
      }
    }

    animation: blinker 2s infinite;
    background: get-color("magenta");
    border-radius: 100%;
    box-shadow: 0 0 0 0 get-color("magenta");
    height: get-baseline(3);
    margin-left: get-baseline(2);
    margin-top: 5px;
    width: get-baseline(3);

    @include mq(m) {
      height: get-baseline(4);
      width: get-baseline(4);
    }
  }

  &__wrapper {
    grid-column: 1 / span 6;
  }

  &__image {
    @include ratio-container(16, 9);

    margin-bottom: get-baseline(3);
  }

  &__content {
    grid-column: 1 / span 5;
    padding-left: get-baseline(4);

    @include mq(m) {
      grid-column: 1 / span 6;
      margin-left: calc(0.5 * var(--column-width));
      padding-left: 0;
    }

    & > * + * {
      margin-top: get-baseline(2);
    }
  }

  &--is-live &__more-button {
    display: none;
  }

  &__live-button {
    display: none;
  }

  &--is-live &__live-button {
    display: inline-flex;
  }
}
