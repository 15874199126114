.o-ticket-teasers {
  @include grid-container;

  margin: 0 auto;

  &__headline {
    grid-column: 1 / -1;
    text-align: center;
    margin-bottom: get-baseline(8);
  }

  &__image {
    aspect-ratio: 9 / 5;
    width: 100%;
    background: get-color("grey");

    img {
      width: 100%;
      height: auto;
    }
  }

  &__tickets {
    grid-column: 1 / -1;
    grid-row-gap: get-baseline(6);

    @include grid-container(1);

    @include mq(l) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row-gap: get-baseline(10);
    }
  }

  &__ticket {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    flex-direction: column;
    gap: get-baseline(2);
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: get-baseline(3);
  }
}
