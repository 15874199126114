.o-ticket-list {
  $module: &;

  &__headline-wrapper {
    @include grid-container(1);

    text-align: center;
    grid-gap: get-baseline(2);
    margin: get-baseline(1) auto get-baseline(7);
  }

  &__item {
    margin-bottom: get-baseline(7);

    address {
      font-style: normal;
      margin: 0;
    }
  }

  &__item-time--desktop {
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &__item-time--mobile {
    display: block;

    @include mq(m) {
      display: none;
    }
  }

  &__item-wrapper {
    @include grid-container(6);
    @include default-box-shadow;

    width: 100%;
    background-color: get-color("grey");
    padding: get-baseline(3) get-baseline(2);
    margin: 0 auto;

    @include mq(m) {
      @include grid-container(12);

      padding: get-baseline(5) 0;
    }
  }

  &__item-content {
    @include grid-item(1, 6);

    display: flex;
    flex-direction: column;
    gap: get-baseline(2);

    @include mq(m) {
      @include grid-item(5, 11);
    }

    @media (min-width: 1280px) {
      @include grid-item(4, 11);
    }
  }

  &__item-date {
    display: none;

    @include mq(m) {
      @include grid-item(1, 4);

      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 0 get-baseline(2);
    }

    @media (min-width: 1280px) {
      @include grid-item(1, 3);

      padding: 0 get-baseline(6);
    }
  }

  &__item-category {
    @include default-box-shadow;
    @include grid-container(6);

    margin: get-baseline(1) auto 0;
    align-items: center;
    height: get-baseline(9);
    grid-template-rows: 1fr;
    background-color: get-color("grey");

    @include mq(m) {
      @include grid-container(12);
    }
  }

  &__category-name {
    @include grid-item(1, 3);

    padding: 0 get-baseline(2);

    @include mq(m) {
      @include grid-item(1, 8);

      padding: 0 get-baseline(8);
    }
  }

  &__category-meta {
    @include grid-item(4, 6);

    justify-self: end;
    padding: 0 get-baseline(2);

    @include mq(m) {
      @include grid-item(9, 11);

      padding: 0;
    }
  }

  &__cta-wrapper {
    position: relative;
  }

  &__hint {
    margin: get-baseline(1) auto 0;

    @include grid-container(1);
  }

  &__cta {
    display: none;
    justify-content: end;
  }

  &__state {
    &--available,
    &--expired,
    &--upcoming {
      display: none;
    }
  }

  &[data-state="upcoming"] {
    #{$module}__state--upcoming {
      display: block;
    }

    #{$module}__cta {
      display: inline-flex;
    }
  }

  &[data-state="available"] {
    color: get-color("white");

    #{$module}__state--available {
      display: block;
    }

    #{$module}__cta {
      display: inline-flex;
    }
  }

  &[data-state="expired"] {
    #{$module}__state--expired {
      display: block;
    }

    #{$module}__cta {
      display: inline-flex;
    }
  }
}
