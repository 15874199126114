.m-accordion {
  &__headline {
    margin-bottom: get-baseline(4);

    @include mq(m) {
      margin-bottom: get-baseline(6);
    }
  }

  details {
    &:not(:first-of-type) {
      margin-top: get-baseline(3);

      @include mq(m) {
        margin-top: get-baseline(4);
      }
    }
  }

  summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    list-style: none;

    @include font-copy;

    font-weight: get-weight("bold");

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__icon {
    width: 20px;
    height: 18px;
    flex-shrink: 0;
    margin-right: get-baseline(3);
  }

  &__content {
    margin-top: get-baseline(1);
    padding-left: 40px;

    @include mq(s) {
      padding-left: 41px;
    }

    *:first-child {
      margin-top: 0;
    }
  }

  details[open] summary .m-accordion__icon {
    transform: rotate(180deg);
  }
}
