.m-content-image {
  $module: &;

  color: get-color("white");

  &--flex {
    cursor: pointer;
  }

  &__image {
    position: relative;
  }

  &__icon {
    display: none;
    position: absolute;
    right: get-baseline(2);
    top: get-baseline(2);
    width: get-baseline(3);
    height: get-baseline(3);

    .m-content-image--flex & {
      display: block;
    }

    .a-svg-sprite {
      width: 100%;
      height: 100%;
    }
  }

  &__caption {
    margin-top: get-baseline(1);
  }

  img {
    width: 100%;
    height: auto;
  }
}
