.m-gallery-slider {
  position: relative;
  padding: get-baseline(6) 0;

  @include grid-container();

  grid-row-gap: get-baseline(6);
  overflow: hidden;

  h2 {
    hyphens: auto;
  }

  &__text {
    margin-bottom: get-baseline(3);
  }

  h2,
  &__text {
    @include grid-item(2, 6);

    @include mq(m) {
      @include grid-item(4, -4);
    }

    @include mq(l) {
      @include grid-item(4, -7);
    }
  }

  &__slides-wrapper {
    @include grid-container;
    @include grid-item(1, -1);

    position: relative;

    &::before,
    &::after {
      content: "";
      bottom: 0;
      position: absolute;
      top: 0;
      width: 20px;
      z-index: 1;

      @include mq(m) {
        width: 40px;
      }
    }

    &::before {
      left: 0;
      background: linear-gradient(90deg, var(--theme-background), transparent);
    }

    &::after {
      right: 0;
      background: linear-gradient(-90deg, var(--theme-background), transparent);
    }
  }

  &__slides {
    --px: #{map-deep-get($grid-configuration, "mobile", "spaceAround")};
    --pt: #{get-baseline(3)};
    --pb: #{get-baseline(2)};

    @include grid-item(1, -1);

    padding: var(--pt) var(--px) var(--pb);

    @include mq(m) {
      --px: #{map-deep-get($grid-configuration, "desktop", "spaceAround")};
      --pt: #{get-baseline(4)};
    }
  }

  &__slide {
    overflow: visible !important;
    height: 300px;

    @include mq(m) {
      height: 400px;
    }

    @include mq(l) {
      height: 450px;
    }

    img {
      transition: transform 0.3s ease;
      height: 100%;
    }

    @media (pointer: fine) {
      &:hover img {
        transform: scale(1.04);
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;

    @include grid-item(2, -2);
  }

  button {
    align-items: center;
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: get-baseline(4) get-baseline(4);

    &:focus {
      @include focus-global;
    }

    .a-svg-sprite {
      display: block;
      height: get-baseline(4);
      transition: transform 0.3s ease;
      width: get-baseline(4);
    }
  }

  &__prev:hover .a-svg-sprite {
    transform: translateX(-3px);
  }

  &__next:hover .a-svg-sprite {
    transform: translateX(3px);
  }

  &__counter {
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: tabular-nums;
  }
}
