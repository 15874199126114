.m-callout {
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
  padding: get-baseline(3) 0;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &--init {
    opacity: 1;
  }

  @include mq(m) {
    justify-content: flex-end;
    padding-bottom: 0;
    margin-bottom: get-baseline(-2);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;

    @include mq(m) {
      margin-right: get-baseline(-10);
    }

    @include mq(l) {
      max-width: 700px;
    }
  }

  &__countdown {
    .a-countdown__item {
      text-transform: uppercase !important;
    }
  }

  &--is-live &__countdown {
    display: none;
  }

  &__live {
    display: none;
  }

  &--is-live &__live {
    display: block;
  }

  &__cta {
    position: relative;
    padding: 0 get-baseline(4);
    height: 36px;
    display: flex;
    align-items: center;
    margin-left: get-baseline(4);

    svg {
      width: get-baseline(4);
      height: get-baseline(4);
      transition: all 0.2s ease-in-out;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      transform: skewX(20deg);
      background: get-color("magenta");
    }
  }

  &__measurements {
    padding: 0 get-baseline(4);
    opacity: 0;
    user-select: none;
    pointer-events: none;
    text-align: center;

    @include mq(m) {
      text-align: right;
      padding-right: get-baseline(13);
    }

    span::after {
      content: " ";
    }
  }

  &__words-wrapper {
    position: relative;
    align-self: flex-end;
  }

  &__words {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(m) {
      align-items: flex-end;
    }

    span {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 get-baseline(4);

      @include mq(m) {
        padding-right: get-baseline(13);
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        transform: skewX(20deg);
        background: get-color("magenta");
      }
    }
  }

  &:hover &__cta svg,
  &:focus &__cta svg {
    transform: translateX(4px);
  }
}
