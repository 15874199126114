.o-share-button {
  display: flex;
  gap: get-baseline(1);

  &__buttons {
    display: none;
  }

  &--is-expanded &__buttons {
    display: flex;
    gap: get-baseline(1);
  }
}
