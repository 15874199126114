.m-notify {
  margin-bottom: get-baseline(4);

  &__wrapper {
    background-color: get-color("grey");
    border: 1px solid get-color("grey-light");
    border-radius: 4px;
    color: get-color("white");
    padding: get-baseline(3);
  }

  &--success &__wrapper {
    border-color: get-color("green");
  }

  &--error &__wrapper {
    border-color: get-color("red");
  }
}
