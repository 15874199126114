$teaser-overlay-opacity: 0.1;
$teaser-lower-half-overlay-opacity: 0.7;
$teaser-shadow-default-mobile: 0 20px 40px -10px rgba(#000, 0.9);
$teaser-shadow-default-desktop: 0 50px 50px -25px rgba(#000, 0.9);
$teaser-shadow-hover-mobile: 0 18px 50px -10px rgba(#000, 0.75);
$teaser-shadow-hover-desktop: 0 45px 60px -25px rgba(#000, 0.75);

.m-teaser {
  $teaser: &;

  cursor: pointer;
  position: relative;
  transform: translateZ(0);
  width: 100%;
  z-index: 0;
  transition: all 400ms cubic-bezier(0.5, 1, 0.1, 1);
  text-decoration: none;

  &__image {
    display: block;
    height: auto;
    transition: all 0.3s ease;
    width: 100%;
  }

  &__image-wrapper {
    pointer-events: none;
    position: relative;
    z-index: 1;
    aspect-ratio: var(--ratio);
    box-sizing: border-box;
    background-color: get-color("grey");

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: 0;
    }

    &::after {
      box-shadow: $teaser-shadow-default-mobile;
      content: "";
      transition: all 400ms cubic-bezier(0.5, 1, 0.1, 1);
      z-index: -1;

      @include mq(m) {
        box-shadow: $teaser-shadow-default-desktop;
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      z-index: 2;
      object-fit: cover;
    }
  }

  &__headline {
    text-align: left;
    color: get-color("white");
    margin: 0;
    position: relative;
    transform: translateZ(0);
    width: 100%;
    word-wrap: break-word;
    z-index: 2;
    margin-top: get-baseline(1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__statuslane {
    position: relative;
    z-index: 2;
  }

  &:hover {
    z-index: 1;
    transform: scale(1.04);

    .m-teaser__image-wrapper::after {
      box-shadow: $teaser-shadow-hover-mobile;

      @include mq(m) {
        box-shadow: $teaser-shadow-hover-desktop;
      }
    }
  }
}
