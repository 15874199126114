$heightConfig: (
  init: get-baseline(70),
  breakpoints: (
    s: 420px,
    l: 520px
  )
);

.m-page-intro {
  $page-into: &;

  color: get-color("white");
  position: relative;
  max-width: 1680px;
  margin: 0 auto;
  width: 100%;

  &__inner {
    @include grid-container;

    height: 100%;
    position: relative;
  }

  &__headline-box {
    @include grid-item(2, -2);
  }

  &--has-badge & {
    &__headline-box {
      @include mq(m) {
        @include grid-item(2, 10);
      }
    }
  }

  &__media + &__inner {
    margin-top: -25%;

    @include mq(m) {
      margin-top: -10%;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    margin: 0 0 get-baseline(2);
    width: 100%;
  }

  &__headline-button {
    margin-right: get-baseline(2);
    white-space: nowrap;
  }

  &__media {
    height: get-baseline(70);
    position: relative;
    z-index: 0;

    @each $key, $val in map-get($heightConfig, breakpoints) {
      @include mq($key) {
        height: $val;
      }
    }
  }

  &__image-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;

    &::after {
      background-color: get-color("black");
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0.7;
    }
  }

  &__image {
    height: 100%;
    left: auto;
    position: relative;
    top: auto;
    transform: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__logo {
    height: get-baseline(27);
    left: 50%;
    max-width: get-baseline(105);
    padding: 0 get-baseline(2);
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;
    transform: translate(-50%, -90%);

    @include mq(m) {
      transform: translate(-50%, -70%);
    }

    @include mq(l) {
      height: get-baseline(45);
    }

    &-image {
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
    }
  }

  &__overlay {
    background-image: linear-gradient(
      transparent,
      rgba(get-color("grey-dark"), 1)
    );
    bottom: -1px;
    height: 50%;
    position: absolute;
    width: 100%;
  }

  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: get-baseline(-2);
      display: flex;
      justify-content: center;

      @include mq(m) {
        justify-content: flex-end;
      }

      img {
        width: 100%;
        max-width: 150px;
        max-height: 100%;
        object-fit: contain;
        align-self: flex-start;
        animation-direction: alternate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: swing;
        animation-play-state: running;
        animation-timing-function: ease-in-out;

        @include mq(m) {
          @include grid-size("margin-right", "desktop", 1);
          @include grid-size("max-width", "desktop", 3);
        }
      }
    }
  }
}

@keyframes swing {
  from {
    filter: drop-shadow(10px 10px 3px rgba(#000, 0.3));
    transform: rotate(-1deg);
    transform-origin: top center;
  }

  to {
    filter: drop-shadow(10px 10px 10px rgba(#000, 0.3));
    transform: rotate(1deg);
    transform-origin: top center;
  }
}
