.o-mobile-prompt {
  background: rgba(get-color("black"), 0.9);
  bottom: 0;
  color: get-color("white");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding-bottom: 35%;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999999;

  &--hidden {
    display: none;
  }

  &__content {
    @include grid-container;

    &__wrapper {
      @include grid-item(2, -2);
    }
  }

  &__text {
    @include font-headline-medium($extra: false);

    color: get-color("white-dark");

    span {
      @include magenta-musik-logo;

      color: get-color("white");
      display: block;
      margin: get-baseline(2) 0;
    }
  }

  &__buttons {
    margin-top: get-baseline(3);
  }

  &__link {
    margin-top: get-baseline(7);
  }
}
