.o-teaser-rows {
  @include grid-container;

  margin: 0 auto get-baseline(10);
  row-gap: get-baseline(10);

  @include mq(m) {
    margin-bottom: get-baseline(15);
    row-gap: get-baseline(15);
  }

  &__row {
    z-index: 2;

    &--ad-banner,
    &--livestream-teaser,
    &--newsletter-teaser,
    &--ticket-teasers,
    &--social-newsletter-row,
    &--video-banner {
      @include grid-item(2, -2);
    }

    &--hero-teaser,
    &--highlight-video-teaser,
    &--music-finder,
    &--page-description,
    &--product-teaser,
    &--teaser-grid,
    &--teaser-lane,
    &--ticket-countdown-teaser,
    &--teaser-slider,
    &--upright-stage {
      @include grid-item(1);
    }
  }
}
