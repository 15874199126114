/* stylelint-disable */
// Default print styling from https://html5boilerplate.com/
*,
*::before,
*::after {
  @media print {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
    text-shadow: none !important;
  }
}

a,
a:visited {
  @media print {
    text-decoration: underline;
  }
}

a[href]::after {
  @media print {
    content: ' (' attr(href) ')';
  }
}

abbr[title]::after {
  @media print {
    content: ' (' attr(title) ')';
  }
}

//  Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol
a[href^='#']::after,
a[href^='javascript:']::after {
  @media print {
    content: '';
  }
}

pre {
  @media print {
    white-space: pre-wrap !important;
  }
}

pre,
blockquote {
  @media print {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
}

// Printing Tables: http://css-discuss.incutio.com/wiki/Printing_Tables
thead {
  @media print {
    display: table-header-group;
  }
}

tr,
img {
  @media print {
    page-break-inside: avoid;
  }
}

p,
h2,
h3 {
  @media print {
    orphans: 3;
    widows: 3;
  }
}

h2,
h3 {
  @media print {
    page-break-after: avoid;
  }
}
/* stylelint-enable */
