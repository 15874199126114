.o-article-intro {
  @include grid-container;

  grid-row-gap: get-baseline(5);
  margin-bottom: get-baseline(6);

  @include mq(m) {
    margin-bottom: get-baseline(11);
  }

  &__headline,
  &__intro {
    @include grid-item(2, -2, "mobile");

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }

    @include mq(l) {
      @include grid-item(4, -4, "desktop");
    }
  }

  &__headline {
    & > span {
      margin-bottom: get-baseline(1);
    }
  }
}
