.o-video-banner {
  display: block;
  text-decoration: none;
  width: 100%;
  background-color: get-color("grey");
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  min-height: 100px;

  &::after {
    @include default-box-shadow;

    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 0.2s ease-in-out;
    z-index: -1;
  }

  &:hover::after {
    transform: scale(0.9);
  }

  &__wrapper {
    //
  }

  &__video {
    pointer-events: none;
    width: 100%;
    display: block;

    &--mobile {
      @include mq(m) {
        display: none;
      }
    }

    &--desktop {
      @include mq($until: m) {
        display: none;
      }
    }
  }
}
