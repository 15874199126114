.t-prio-ticket {
  margin-top: get-baseline(2);
  position: relative;
  row-gap: get-baseline(10);

  @include mq(m) {
    margin-top: get-baseline(7);
    row-gap: get-baseline(15);
  }

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__ticket-faq {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }

    @include mq(l) {
      @include grid-item(4, -4, "desktop");
    }

    margin: get-baseline(7) 0 0;
  }

  &__ticket-list {
    @include grid-item(2, -2);

    margin: get-baseline(10) 0 0;
  }

  &__text {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(2, -6, "desktop");
    }

    margin: get-baseline(10) 0 0;
  }

  &__ticket-stage {
    @include grid-item(1);
  }
}
