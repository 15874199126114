.m-content-teaser {
  text-decoration: none;

  @include mq(m) {
    @include grid-container(2);

    grid-template-rows: auto 1fr;
  }

  h3 {
    margin-bottom: get-baseline(3);

    @include mq(m) {
      align-self: start;
      grid-row: 1;
      grid-column: 2 / span 1;
      margin-bottom: 0;
      padding-right: get-baseline(6);
    }
  }

  &__text > * {
    margin-top: get-baseline(3);

    @include mq(m) {
      grid-row: 2;
      grid-column: 2 / span 1;
    }
  }

  img {
    width: 100%;
    margin-bottom: get-baseline(4);

    @include mq(m) {
      margin-bottom: 0;
      grid-row: 1 / span 2;
      grid-column: 1 / span 1;
    }
  }

  &__copy,
  &__cta {
    margin-left: get-baseline(4);

    @include mq(m) {
      margin-left: 0;
    }
  }
}
