// @font-face {
//   font-display: swap;
//   font-family: TeleNeo;
//   src: url("./teleneoweb-thin.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
// }

@font-face {
  font-display: swap;
  font-family: TeleNeo;
  src: url("./teleneoweb-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: TeleNeo;
  src: url("./teleneoweb-regularitalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

// @font-face {
//   font-display: swap;
//   font-family: TeleNeo;
//   src: url("./teleneoweb-medium.woff2") format("woff2");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-display: swap;
//   font-family: TeleNeo;
//   src: url("./teleneoweb-mediumitalic.woff2") format("woff2");
//   font-weight: 500;
//   font-style: italic;
// }

@font-face {
  font-display: swap;
  font-family: TeleNeo;
  src: url("./teleneoweb-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: TeleNeo;
  src: url("./teleneoweb-bolditalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: TeleNeo;
  src: url("./teleneoweb-ultra.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
