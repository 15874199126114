.m-icon-row {
  &__wrapper {
    display: flex;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__item {
    padding: get-baseline(4) get-baseline(2);
    width: 100%;

    &:first-child {
      @include mq(m) {
        padding-left: 0;
      }
    }

    &:last-child {
      @include mq(m) {
        padding-right: 0;
      }
    }

    @include mq(m) {
      display: flex;
      padding: get-baseline(5) get-baseline(2);
      justify-content: center;
    }
  }

  &__item-content {
    align-items: center;
    color: get-color("white");
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__icon-wrapper {
    align-items: center;
    display: flex;
    height: 125px;
    justify-content: center;
    margin: 0 auto;
    width: 150px;

    @include mq(m) {
      align-items: flex-end;
    }

    svg {
      fill: get-color("white");
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  &__text {
    margin-top: get-baseline(3);

    @include mq(m) {
      margin-top: get-baseline(5);
    }

    h2 {
      margin-bottom: get-baseline(2);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: get-baseline(3);
    justify-content: center;
    align-items: center;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__link {
    &:not(:last-child) {
      margin: 0 0 get-baseline(2) 0;

      @include mq(m) {
        margin: 0 get-baseline(2) 0 0;
      }
    }
  }
}
