/// Debugging scss

$debug: false;

/// Prevent long strings like URLs from breaking the browser window boundaries
/// @link https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/

@mixin dont-break-out() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

/// Gradient

@mixin gradient() {
  background-image:
    linear-gradient(
      transparent,
      rgba(get-color("grey-dark"), 1)
    );
}

/// Mark illegal child elements (only works, when $debug is set to true)
/// @param {Map} $elements [()] Map of legal child elements
/// @requires {Variable} $debug
/// @example
///   @include allowed-children((h2, h3, h4, h5, h6, p, ul, ol));

@mixin allowed-children($elements: ()) {
  @if $debug {
    $selector: "";
    $allowedChildren: "";

    // Build string $selector and $allowedChildren

    @each $el in $elements {
      $i: index($elements, $el);
      $selector: "#{$selector}:not(#{$el})";

      @if $i == 1 {
        $allowedChildren: "#{$el}";
      } @else {
        $allowedChildren: "#{$allowedChildren}, #{$el}";
      }
    }

    > * {
      &#{$selector},
      #{$selector} {
        outline: 1px dashed #f00;
        outline-offset: 0.5rem;
        position: relative;

        &::before {
          background-color: #f00;
          bottom: 100%;
          color: white;
          content: "Element is not allowed. Allowed elements are: #{$allowedChildren}";
          display: block;
          font-family: monospace;
          font-size: 0.8rem;
          left: 0;
          padding: 0.1rem 0.2rem;
          position: absolute;
        }
      }
    }
  }
}

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

/// Global focus styles

@mixin focus-global($color: "blue") {
  [data-whatinput="keyboard"] & {
    box-shadow: 0 0 2px 2px get-color($color);
  }
}

@mixin focus-global-big($color: "blue") {
  [data-whatinput="keyboard"] & {
    box-shadow: 0 0 10px 0 get-color($color);
  }
}

/// Text selection styles

@mixin selection-global() {
  background: get-color("magenta");
  color: get-color("white");
}

/// Magenta Boxshadow size (used for padding and margin of items inside overflow:hidden containers, example in src/components/organisms/teaser-slider/_teaser-slider.scss)

$box-shadow-substraction: 120px;

/// Magenta Boxshadow

@mixin default-box-shadow() {
  $color: rgba(get-color("black"), 0.9);

  box-shadow: 0 20px 55px -10px $color;

  @include mq(m) {
    box-shadow: 0 30px 75px -15px $color;
  }

  @include mq(l) {
    box-shadow: 0 40px 80px -20px $color;
  }
}

/// Mixin that calculates if text needs to be light or dark, depending on the background color passed.
/// Color brightness is determined by the following formula:
/// ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
/// @author David Halford
/// @link https://codepen.io/davidhalford/pen/wlDxL
/// @link http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
/// @param {Color} $color Color
/// @returns {Color} Light or dark color depending on the contrast to $color

@function text-contrast($color) {
  $contrast-color: #fff;
  $color-brightness: round(
    (red($color) * 299) + (green($color) * 587) + (blue($color) * 114) * 0.001
  );
  $light-color: round(
    (red(#fff) * 299) + (green(#fff) * 587) + (blue(#fff) * 114) * 0.001
  );

  @if abs($color-brightness) < ($light-color * 0.5) {
    $contrast-color: #fff;
  } @else {
    $contrast-color: #000;
  }

  @return $contrast-color;
}

/// Hide visually
/// @link https://a11yproject.com/posts/how-to-hide-content/

@mixin hide-visually() {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

@mixin ratio-container($width: 16, $height: 9) {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc($height / $width * 100%);
  box-sizing: border-box;

  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
