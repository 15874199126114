.t-street-gigs-form {
  position: relative;

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__stepper {
    margin: get-baseline(7) 0 0;

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(4, -4, "desktop");
    }

    @include mq(l) {
      @include grid-item(5, -5, "desktop");
    }
  }

  &__header-image {
    @include grid-item(2, -2);

    aspect-ratio: 1013 / 315;
    margin: get-baseline(7) 0 0;
    width: 100%;

    @include mq(m) {
      @include grid-item(4, -4, "desktop");
    }
  }

  &__title {
    margin: get-baseline(7) 0 0;

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(4, -4, "desktop");
    }

    @include mq(l) {
      @include grid-item(5, -5, "desktop");
    }
  }
}
