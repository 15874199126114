.o-ad-banner {
  color: inherit;
  display: block;
  text-decoration: none;

  h2 {
    margin-bottom: get-baseline(3);
  }

  &__image-wrapper {
    overflow: hidden;
    padding-bottom: 31.2878%; // aspect ratio ~ 16 x 5
    position: relative;
  }

  &__picture-wrapper {
    overflow: hidden;
    padding-bottom: 100%; // aspect ratio ~ 1 x 1
    position: relative;

    @include mq(m) {
      padding-bottom: 43.75%; // aspect ratio ~ 16 x 7
    }

    @include mq(l) {
      padding-bottom: 31.2878%; // aspect ratio ~ 16 x 5
    }
  }

  img,
  picture {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__label {
    display: block;
    margin-top: get-baseline(1);
  }
}
