.m-gallery {
  position: relative;
  padding: 0;

  @include grid-container;

  overflow: hidden;

  h2 {
    hyphens: auto;
    margin-bottom: get-baseline(6);
  }

  &__text {
    margin-bottom: get-baseline(9);
  }

  h2,
  &__text {
    @include grid-item(2, 6);

    @include mq(m) {
      @include grid-item(4, -4);
    }

    @include mq(l) {
      @include grid-item(4, -7);
    }
  }

  &__slides-container {
    @include grid-item(2, -2);

    position: relative;
    cursor: grab;

    @include mq(l) {
      @include grid-item(3, -3);
    }
  }

  &__slides {
    @include ratio-container(16, 9);
  }

  &__slide {
    transition: transform 0.3s ease-in-out;
    transform: scale(0.7);
    user-select: none;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      user-select: none;
    }

    &::after {
      position: absolute;
      inset: -1px;
      content: "";
      background-color: get-color("grey-dark");
      transition: opacity 0.3s ease-in-out;
    }

    &[aria-current="true"] {
      z-index: 3;
      transform: none;

      &::after {
        opacity: 0;
      }
    }

    &[data-prev-slide="true"] {
      transform: scale(0.9) translate(-80px, 0);
      z-index: 1;

      &::after {
        opacity: 0.8;
      }

      @include mq(s) {
        transform: scale(0.9) translate(-50px, 0);
      }

      @include mq(m) {
        transform: scale(0.9) translate(-80px, 0);
      }

      @include mq(l) {
        transform: scale(0.9) translate(-100px, 0);
      }
    }

    &[data-next-slide="true"] {
      transform: scale(0.9) translate(80px, 0);
      z-index: 2;

      &::after {
        opacity: 0.8;
      }

      @include mq(s) {
        transform: scale(0.9) translate(50px, 0);
      }

      @include mq(m) {
        transform: scale(0.9) translate(80px, 0);
      }

      @include mq(l) {
        transform: scale(0.9) translate(100px, 0);
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;

    @include grid-item(2, -2);
  }

  button {
    align-items: center;
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: get-baseline(4) get-baseline(4);

    &:focus {
      @include focus-global;
    }

    .a-svg-sprite {
      display: block;
      height: get-baseline(4);
      transition: transform 0.3s ease;
      width: get-baseline(4);
    }
  }

  &__prev:hover .a-svg-sprite {
    transform: translateX(-3px);
  }

  &__next:hover .a-svg-sprite {
    transform: translateX(3px);
  }

  &__counter {
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: tabular-nums;
  }
}
