.o-footer {
  color: get-color("white");
  overflow: hidden;
  padding-top: get-baseline(18);
  width: 100%;

  &__social {
    background: url("wave-mobile.svg") no-repeat;
    background-size: cover;
    background-position: top center;
    margin-bottom: 50px;
    background-position-x: calc(-350px + var(--wave-offset-x));

    @include mq(m) {
      background-image: url("wave-desktop.svg");

      /* 1025px is the center of the left wave maximum on the x-axis */
      background-position-x: calc(-1430px + var(--wave-offset-x));
    }

    @include mq(l) {
      background-position-x: calc(-1685px + var(--wave-offset-x));
      background-repeat: repeat-x;
    }
  }

  &__social-grid {
    @include grid-container;

    grid-auto-rows: auto; // Reset value from grid-container() so the grid takes up the fixed height
    height: 299px;
    margin: 0 auto;
    place-items: center center;

    @include mq(m) {
      height: 550px;
      padding: get-baseline(8) 0;
    }

    @include mq(l) {
      height: 650px;
    }
  }

  &__social-inner {
    @include grid-item(2, 6);

    text-align: center;

    @include mq(m) {
      @include grid-item(2, 6);
    }

    @include mq(l) {
      @include grid-item(2, 5);
    }

    h3 {
      margin-bottom: get-baseline(2);
    }

    p {
      margin-bottom: get-baseline(4);
    }

    ul {
      display: flex;
      justify-content: center;

      & > li + li {
        margin-left: get-baseline(3);
      }
    }
  }

  &__social-link {
    width: get-baseline(4);
    height: get-baseline(4);
    display: block;

    &:hover {
      color: get-color("white-dark");
    }
  }

  &__bottom-bar {
    background: get-color("grey-medium");
  }

  &__bottom-bar-grid {
    @include grid-container;

    margin: 0 auto;
  }

  &__bottom-bar-body {
    @include grid-item(2, -2);

    margin: 24px 0 36px;

    @include mq(m) {
      margin: 24px 0 48px;
    }
  }

  &__bottom-bar-logo {
    margin-bottom: 36px;

    @include mq(m) {
      margin-bottom: 24px;
    }

    svg {
      width: 129px;
      height: 38px;
    }
  }

  &__bottom-bar-info-group {
    @include mq(xl) {
      gap: 24px;
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }

  &__copyright {
    margin-bottom: get-baseline(3);

    @include mq(xl) {
      margin-bottom: 0;
    }
  }

  &__navigation {
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      column-gap: 36px;

      @include mq(m) {
        flex-direction: row;
      }

      @include mq(xl) {
        justify-content: end;
      }
    }
  }

  &__navigation-link {
    display: block;
    text-decoration: none;

    &:active span {
      color: get-color("grey-light");
    }

    &:hover span,
    &:focus span {
      text-decoration: underline;
    }
  }
}
