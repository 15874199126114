.m-stepper {
  --gap: #{get-baseline(2)};

  display: flex;
  gap: var(--gap);
  justify-content: center;

  @include mq(m) {
    --gap: #{get-baseline(4)};
  }

  &__step {
    --indicator-margin: #{get-baseline(1.5)};
    --indicator-size: #{get-baseline(2)};

    align-items: center;
    color: get-color("white");
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    @include mq(m) {
      --indicator-margin: #{get-baseline(1.5)};
      --indicator-size: #{get-baseline(3)};
    }

    &--active {
      --indicator-margin: #{get-baseline(1)};
      --indicator-size: #{get-baseline(3)};

      color: get-color("magenta");

      @include mq(m) {
        --indicator-margin: #{get-baseline(1)};
        --indicator-size: #{get-baseline(4)};
      }
    }
  }

  &__step-indicator {
    background: currentcolor;
    border-radius: var(--indicator-size);
    height: var(--indicator-size);
    margin: var(--indicator-margin) 0;
    width: var(--indicator-size);
    z-index: 1;
  }

  &__step-label {
    text-align: center;
  }

  &__step-separator {
    --height: 4px;

    background: url("data:image/svg+xml,%3Csvg width='14' height='4' viewBox='0 0 14 4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234B4B4B' d='M0 0h7v4H0z'/%3E%3C/svg%3E");
    height: var(--height);
    left: calc(-50% - var(--gap));
    position: absolute;
    top:
      calc(
        var(--indicator-margin) + var(--indicator-size) / 2 - var(--height) / 2
      );
    width: calc(100% + var(--gap));
  }

  &__step--active &__step-separator {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='4' viewBox='0 0 14 4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23e20074' d='M0 0h7v4H0z'/%3E%3C/svg%3E");
  }
}
