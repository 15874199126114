.m-newsletter-teaser {
  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: get-baseline(2);
    justify-content: center;

    @include mq(m) {
      align-items: flex-start;
      flex-direction: row;
    }

    .a-input {
      margin: 0;
    }

    .a-button {
      margin: 2px 0;
    }
  }
}
