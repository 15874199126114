.o-video-player {
  @include grid-container;

  position: relative;

  &__player-container {
    @include grid-item(1, -1);

    @include mq(m) {
      @include grid-item(1, -1, "desktop");
    }

    // MM360-1294 Minimum screen width at which the player can have >= 768px width inside the grid
    @media (min-width: 918px) {
      @include grid-item(3, -3, "desktop");
    }

    position: relative;
  }

  &--has-overlay &__player-container {
    display: grid;
    grid-template-areas: "a";
  }

  &__player-ratio {
    /* magenta header height + margin between header and video + height of the switch player button */
    --margin-top: calc(var(--mm-header-height) + #{get-baseline(3)});
    --min-height: auto;

    aspect-ratio: 16 / 9;
    margin: 0 auto;
    min-height: var(--min-height);

    @include mq(m) {
      --min-height: 320px;
      --max-height: calc(100vh - var(--margin-top) - #{get-baseline(8)});

      max-height: var(--max-height);
    }

    @include mq(l) {
      --min-height: 480px;
      --max-height: calc(100vh - var(--margin-top) - #{get-baseline(12)});
    }
  }

  &--has-overlay &__player-ratio {
    grid-area: a;
    max-width: 100%;

    @include mq($until: m) {
      width: 100%;
    }
  }

  &__player-wrapper {
    @include default-box-shadow;

    background: get-color("black");

    // MM360VP-151 Disable focus-global from __base.scss for the video player
    [tabindex="-1"] {
      [data-whatinput="keyboard"] &:focus {
        box-shadow: none;
      }
    }
  }

  &__infobar {
    @include grid-item(2, -2);

    z-index: 1;

    @include mq(m) {
      @include grid-item(4, -4, "desktop");
    }
  }

  &__textbox {
    @include grid-item(2, -2);

    padding: get-baseline(4) 0 get-baseline(1);

    @include mq(m) {
      @include grid-item(4, -4, "desktop");

      padding: get-baseline(8) 0 get-baseline(5);
    }
  }

  &__textbox-hl {
    margin-bottom: get-baseline(4);

    @include mq(m) {
      margin-bottom: get-baseline(8);
    }
  }

  // Social Wall CTA
  &__social-wall-cta {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(1, -1, "desktop");
    }

    margin: get-baseline(4) 0 get-baseline(8);
  }

  // overlay
  &__overlay {
    grid-area: a;
    align-items: center;
    background-color: get-color("grey-dark");
    color: get-color("white");
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: get-baseline(2);
    position: relative;
    text-align: center;
    top: 0;
    width: 100%;

    // hidden overlay
    &--is-visible {
      @include grid-container(
        map-deep-get($grid-configuration, "mobile", "columns") - 2
      );

      @include mq(m) {
        @include grid-container(
          map-deep-get($grid-configuration, "desktop", "columns") - 2
        );
      }
    }

    @include mq(m) {
      padding: get-baseline(13) 0;
    }

    // Close Button
    &__close {
      position: absolute;
      right: get-baseline(2);
      top: get-baseline(2);
      z-index: 3;
    }

    // Background
    &__background {
      background-color: #000;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      &::before {
        background-image: linear-gradient(
          rgba(get-color("grey-dark"), 0),
          rgba(get-color("grey-dark"), 1)
        );
        content: "";
        height: get-baseline(8);
        left: 0;
        position: absolute;
        top: calc(100% - #{get-baseline(8)});
        width: 100%;
        z-index: 2;
      }

      @include mq($until: m) {
        height: 0;
        padding-bottom: 56.25%;
      }

      &__image {
        background-position: center;
        background-size: cover;
        bottom: 0;
        left: 0;
        opacity: 0.2;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    // Content
    &__content {
      @include grid-item(1);
      @include grid-container(
        map-deep-get($grid-configuration, "mobile", "columns") - 2
      );

      position: relative;
      z-index: 2;

      @include mq(m) {
        @include grid-container(
          map-deep-get($grid-configuration, "desktop", "columns") - 2
        );
      }
    }

    // Datum
    &__date {
      @include font-headline-medium;
      @include grid-item(1, -1);

      @include mq(m) {
        @include grid-item(2, -4, "desktop");
      }

      display: block;
      margin-bottom: get-baseline(1);

      @include mq($until: m) {
        order: 2;
      }
    }

    // Headline
    &__headline {
      @include font-headline-large;
      @include grid-item(1, -1);

      @include mq(m) {
        @include grid-item(3, -5, "desktop");
      }

      display: block;

      @include mq($until: s) {
        order: 3;
      }
    }

    // Countdown
    &__countdown {
      @include grid-item(1, -1);

      @include mq(m) {
        @include grid-item(2, -4, "desktop");
      }

      color: get-color("white");
      margin-top: get-baseline(7);

      @include mq($until: m) {
        order: 1;
      }

      margin-bottom: 0;

      &__text {
        display: block;
        margin: 0 0 get-baseline(2);
      }
    }

    // Text
    &__text {
      @include grid-item(1, -1);

      @include mq(m) {
        @include grid-item(3, -5, "desktop");
      }

      margin: calc(#{get-baseline(1)} * 2) 0 get-baseline(2);

      @include mq($until: m) {
        order: 4;
      }
    }

    // Buttons
    &__button-wrapper {
      @include grid-item(1, -1);

      @include mq(m) {
        @include grid-item(2, -4, "desktop");
      }

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: get-baseline(2);
      flex-direction: row;
      margin-top: get-baseline(2);

      @include mq(m) {
        margin-bottom: get-baseline(-2);
        margin-top: get-baseline(5);
      }

      @include mq(l) {
        margin-top: get-baseline(7);
      }

      @include mq($until: m) {
        order: 5;
      }
    }

    &__button {
      display: inline-block;
      margin: 0 get-baseline(2) get-baseline(2) get-baseline(2);
    }
  }

  .o-references {
    @include grid-item(1, -1);

    @include mq(m) {
      @include grid-item(2, -2, "desktop");
    }
  }
}
