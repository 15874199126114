.m-account-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 get-baseline(-3);
  padding-top: get-baseline(3);

  &__button,
  &__text {
    margin: 0 get-baseline(3) get-baseline(3) 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
