.m-login-account {
  display: inline-block;
  position: relative;

  &__button {
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all 0.15s linear;
    z-index: -1;

    &--visible {
      opacity: 1;
      position: relative;
      z-index: 1;
    }
  }
}
