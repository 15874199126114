.m-banner-teaser {
  padding: get-baseline(5) get-baseline(2);
  position: relative;
  text-align: center;

  @include mq(m) {
    padding: get-baseline(5) get-baseline(5) get-baseline(6);
  }

  > * {
    position: relative;
  }

  &__image {
    height: 100%;
    inset: 0;
    object-fit: cover;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &__headline {
    margin-bottom: get-baseline(2);
  }

  &__content {
    margin-top: get-baseline(3);
  }
}
