.a-playbutton {
  position: relative;

  &__wrapper {
    transform: scale(2);
  }

  .a-button__icon-container {
    transform: translateX(5%);
  }
}
