.a-countdown {
  $this: &;

  display: inline-flex;
  font-variant-numeric: tabular-nums;

  &__item {
    display: inline-block;
    margin-right: 5px;
    text-transform: capitalize;
  }

  &--outline {
    @include font-headline-xlarge-outline;

    display: flex;
  }

  &--outline & {
    &__item {
      display: flex;
      margin-right: get-baseline(2);
      text-transform: none;

      &:last-child {
        margin-right: 0;
      }
    }

    &__value,
    &__key {
      display: block;
    }

    &__key--d::before {
      content: "\00a0";
    }
  }

  &--big {
    #{$this}__value {
      @include font-headline-large;

      font-weight: get-weight("regular"); // Cheating
      align-items: center;
      background: get-color("grey");
      color: get-color("white");
      display: inline-flex;
      height: get-baseline(12);
      justify-content: center;
      margin: 0 get-baseline(1);
      min-width: get-baseline(12);
      padding: get-baseline(2);
      position: relative;

      @include mq(l) {
        height: get-baseline(13);
        min-width: get-baseline(13);
      }
    }

    #{$this}__key {
      @include font-copy;

      color: get-color("white");
      margin: get-baseline(2) get-baseline(1) get-baseline(1);
    }

    #{$this}__item {
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      position: relative;

      &:last-of-type #{$this}__value::after {
        display: none;
      }

      &:not(:last-child) {
        &::after {
          align-items: center;
          content: ":";
          display: flex;
          height: get-baseline(12);
          justify-content: center;
          left: calc(100% - #{get-baseline(1) * 0.5});
          position: absolute;
          text-align: center;
          top: 0;
          width: get-baseline(1);

          @include mq(l) {
            height: get-baseline(13);
          }
        }
      }
    }
  }
}
