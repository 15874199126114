.o-stage-upright {
  &__textwrapper {
    @include grid-container;
  }

  &__topline {
    grid-row: 1;

    @include grid-item(2, 10);

    @include mq(m) {
      @include grid-item(3, 10);
    }
  }

  &__headline {
    grid-row: 2;

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, 10);
    }
  }

  &__slides-wrapper {
    @include grid-container;

    position: relative;

    &::before,
    &::after {
      content: "";
      bottom: 0;
      position: absolute;
      top: 0;
      width: 20px;
      z-index: 1;

      @include mq(m) {
        width: 40px;
      }
    }

    &::before {
      left: 0;
      background: linear-gradient(
        90deg,
        get-color("grey-dark") 0%,
        rgba(get-color("grey-dark"), 0.7) 20%,
        rgba(get-color("grey-dark"), 0.3) 55%,
        rgba(get-color("grey-dark"), 0) 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        -90deg,
        get-color("grey-dark") 0%,
        rgba(get-color("grey-dark"), 0.7) 20%,
        rgba(get-color("grey-dark"), 0.3) 55%,
        rgba(get-color("grey-dark"), 0) 100%
      );
    }
  }

  &__slides {
    --px: #{map-deep-get($grid-configuration, "mobile", "spaceAround")};
    --pt: #{get-baseline(3)};
    --pb: #{get-baseline(2)};

    @include grid-item(1, -1);

    padding: var(--pt) var(--px) var(--pb);

    @include mq(m) {
      --px: #{map-deep-get($grid-configuration, "desktop", "spaceAround")};
      --pt: #{get-baseline(4)};
    }
  }

  &__slide {
    /**
     * Slides Per Page
     */
    --spp: 1.2;
    --gutter: #{map-deep-get($grid-configuration, "mobile", "gutter")};
    --slide-width: calc(
      1 / var(--spp) * 100% - ((var(--spp) - 1) * var(--gutter)) / var(--spp)
    );

    aspect-ratio: 3/4;
    flex: 1 0 var(--slide-width);
    opacity: 0;
    transition: opacity 0.2s 0.05s ease-in-out;
    overflow: visible !important;

    @include mq(m) {
      --spp: 2;
    }

    @media (min-width: 1024px) {
      --spp: 3;
    }

    @include mq(l) {
      --spp: 4;
    }

    img {
      transition: transform 0.3s ease;
      width: 100%;
    }

    @media (pointer: fine) {
      &:hover img {
        transform: scale(1.04);
      }
    }
  }

  &--init &__slide {
    opacity: 1;
  }

  &__controls-wrapper {
    @include grid-container;
  }

  &__controls {
    @include grid-item(2, 6);

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: get-baseline(2);

    @include mq(m) {
      @include grid-item(2, 7);
    }

    @media (min-width: 1024px) {
      @include grid-item(2, 5);
    }

    @include mq(l) {
      @include grid-item(2, 4);
    }
  }

  &__prev-button,
  &__next-button {
    // button reset
    appearance: none;
    border: 0;
    background: none;
    cursor: pointer;

    svg {
      display: block;
      width: 28px;
      height: 28px;
    }
  }

  &__indicators {
    display: flex;
    gap: 3px;
  }

  &__indicator {
    background: get-color("grey-light");
    height: 9px;
    width: 6px;

    &.active {
      background: get-color("magenta");
      width: 9px;
      transform: translateY(-2px);
      height: 13px;
    }
  }
}
