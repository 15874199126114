.t-video-landing-page {
  overflow: initial;

  &__video-meta {
    @include grid-container;
  }

  &__video-meta-inner {
    @include grid-item(2, -2, "mobile");

    @include mq(m) {
      @include grid-item(5, -5, "desktop");
    }
  }
}
