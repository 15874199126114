/* stylelint-disable selector-class-pattern */
.o-ticket-countdown-teaser {
  $this: &;

  @include grid-container;

  .a-countdown__key {
    display: none;
  }

  &__main-headline {
    margin: 0 auto get-baseline(4);
    display: none;

    @include grid-item(1);

    .o-ticket-countdown-teaser__state-is-active {
      display: block;
    }
  }

  &__wrapper {
    @include grid-container;
    @include grid-item(1);
  }

  &__content {
    color: get-color("white");
    padding: get-baseline(4) get-baseline(7);
    text-align: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: get-baseline(2);

    @include mq(l) {
      @include grid-item(1, 4, "desktop");
    }
  }

  &__inner-wrapper {
    @include default-box-shadow;
    @include grid-item(2, -2);

    background: get-color("black");

    @include mq($until: l) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include mq(l) {
      @include grid-container(12);
    }
  }

  &__image {
    @include mq(l) {
      @include grid-item(5, 12, "desktop");
    }

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 9 / 5;
    }
  }

  &__countdown {
    margin-top: get-baseline(1);

    &--is-hidden {
      display: none;
    }
  }

  &__states {
    align-items: center;
    display: flex;
    text-align: center;
    flex: 1;
    justify-content: center;

    @include mq(l) {
      justify-content: flex-start;
    }
  }

  &__state {
    display: none;
    color: get-color("white-dark");

    &--is-active {
      display: block;
    }
  }

  &__date {
    color: get-color("white-dark");
  }

  &__cta {
    margin-top: get-baseline(2);
  }
}
