// Magenta Font Styles
// 3 Breakpoints:
// - Desktop: https://app.zeplin.io/project/5df8951305c6a316072848b5/screen/5e57fba459c6ce69ff65d6b6
// - Tablet: https://app.zeplin.io/project/5df8951305c6a316072848b5/screen/5e57fba488012672f2a5d49d
// - Mobile: https://app.zeplin.io/project/5df8951305c6a316072848b5/screen/5e57fba3c9fe35666330b848
// We write mobile first so we only use the breakpoints 'm' and 'l' from $mq-breakpoints (src/stylesheets/settings/_breakpoints.scss)
// Font-face: @see ../../../fonts../../../fonts.css

/// Font Magenta Musik: TeleNeo. It comes in these variations:
/// - thin
///   - font-weight: 300
///   - font-style: normal
/// - regular
///   - font-weight: 400
///   - font-style: normal, italic
/// - medium
///   - font-weight: 500
///   - font-style: normal, italic
/// - bold
///   - font-weight: 700
///   - font-style: normal, italic
/// - extra
///   - font-weight: 800
///   - font-style: normal

$font-magenta-musik: "TeleNeo", helvetica, arial, sans-serif;

/// Font-weight – The "TeleNeo" uses these terms as description for font weight designations.

$font-magenta-musik-font-weight: (
  "thin": 300,
  "regular": 400,
  "medium": 500,
  "bold": 700,
  "extra": 800
);

@function get-weight($weight: "regular") {
  @return map-get($font-magenta-musik-font-weight, $weight);
}

/// MagentaMusik
/// Use <strong> around „Magenta” like this:
/// @example
///   <strong>Magenta</strong>Musik
/// @param {Number} $size [5] Baseline size
/// @param {Color} $color [get-color('magenta')] Color

@mixin magenta-musik-logo($size: 5) {
  font-family: $font-magenta-musik;
  font-size: get-baseline($size);
  font-weight: get-weight("regular");
  letter-spacing: -0.3px;
  line-height: get-baseline($size);
  text-decoration: none;

  @include mq(m) {
    font-size: get-baseline($size + 2);
    letter-spacing: -0.45px;
    line-height: get-baseline($size + 2);
  }

  @include mq(l) {
    font-size: get-baseline($size + 3);
    letter-spacing: -0.5px;
    line-height: get-baseline($size + 3);
  }

  strong {
    font-weight: get-weight("bold");
  }
}

/// Font: Headline Outline XL
@mixin font-headline-xlarge() {
  @include allowed-children((div, span));

  font-family: $font-magenta-musik;
  font-weight: get-weight("extra");
  letter-spacing: -0.14px;
  font-size: get-baseline(9);
  line-height: get-baseline(9);

  @include mq(m) {
    font-size: get-baseline(13);
    line-height: get-baseline(13);
    letter-spacing: -0.2px;
  }
}

@mixin font-headline-xlarge-outline {
  @include font-headline-xlarge;

  -webkit-text-stroke: 1px get-color("white");
  -webkit-text-fill-color: rgba(get-color("white"), 0);

  @include mq(m) {
    -webkit-text-stroke-width: 1px;
  }
}

/// Font: Headline L
@mixin font-headline-large() {
  @include allowed-children((div, span));

  font-family: $font-magenta-musik;
  font-weight: get-weight("extra");
  letter-spacing: -0.14px;
  font-size: get-baseline(6);
  line-height: get-baseline(6);

  @include mq(l) {
    font-size: get-baseline(9);
    line-height: get-baseline(9);
    letter-spacing: -0.2px;
    text-transform: none;
  }
}

/// Font: Headline M
/// @param {Boolean} $extra [true] Use font-weight extra

@mixin font-headline-medium($extra: false) {
  @include allowed-children((div, span, time, p, strong));

  font-family: $font-magenta-musik;
  font-size: get-baseline(5);
  line-height: get-baseline(5);
  letter-spacing: -0.14px;

  @if $extra {
    font-weight: get-weight("extra");
  } @else {
    font-weight: get-weight("bold");
  }

  @include mq(m) {
    font-size: get-baseline(6);
    line-height: get-baseline(6);
    letter-spacing: -0.2px;
  }

  @include mq(l) {
    font-size: get-baseline(7);
    line-height: get-baseline(7);
  }
}

/// Font: Headline S
/// @param {Boolean} $extra [true] Use font-weight extra
@mixin font-headline-small($extra: false) {
  @include allowed-children((div, span));

  font-family: $font-magenta-musik;
  letter-spacing: 0;
  font-size: 24px;
  line-height: get-baseline(4);

  @if $extra {
    font-weight: get-weight("extra");
  } @else {
    font-weight: get-weight("regular");
  }

  @include mq(m) {
    font-size: 30px;
    line-height: get-baseline(5);
  }
}

@mixin font-copy {
  @include allowed-children((div, span, time));

  font-family: $font-magenta-musik;
  font-size: 16px;
  line-height: 1.5;
  font-weight: get-weight("regular");
  letter-spacing: 0;

  @include mq(m) {
    font-size: 20px;
  }
}

@mixin font-copy-small {
  @include allowed-children((div, span, time));

  font-family: $font-magenta-musik;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0;

  @include mq(m) {
    font-size: 14px;
  }
}
