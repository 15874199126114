.o-ticket-stage {
  $this: &;

  @include grid-container;

  &__wrapper {
    @include grid-container;
    @include grid-item(1);
  }

  &__back-wrapper {
    @include grid-item(2, -2);

    margin-bottom: get-baseline(1);

    @include mq(m) {
      margin-bottom: get-baseline(3);
    }
  }

  &__back {
    border: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 9 / 5;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: get-baseline(2);
  }

  &__image {
    margin-bottom: get-baseline(5);
    aspect-ratio: 9 / 5;
    background: get-color("grey-medium");
  }

  &__content,
  &__image {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }

    @include mq(l) {
      @include grid-item(4, -4, "desktop");
    }
  }
}
