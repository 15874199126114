.t-video-page {
  position: relative;
  color: get-color("white");

  &__background-image {
    width: 100%;
    position: relative;
    height: 50vh;
    margin-top: get-baseline(-3);
    margin-bottom: calc(-50vh + #{get-baseline(3)});
    opacity: 1;
    background-size: cover;

    @include mq(l) {
      height: 500px;
      margin-bottom: calc(-500px + #{get-baseline(3)});
    }

    &__overlay {
      background-image: linear-gradient(
        transparent,
        rgba(get-color("grey-dark"), 1)
      );
      bottom: -1px;
      height: 50%;
      position: absolute;
      width: 100%;
    }
  }

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__video {
    @include grid-item(1);
  }

  &__stream-switch {
    padding: get-baseline(4) get-baseline(2);
    position: relative;
    text-align: center;

    @include mq(m) {
      padding: get-baseline(5) get-baseline(5) get-baseline(4);
    }

    > * {
      position: relative;
    }

    a {
      margin-top: get-baseline(1);
    }
  }

  &__stream-switch {
    @include grid-item(1, -1);

    margin: get-baseline(3) 0;
    margin-bottom: get-baseline(3);
    text-align: center;

    @include mq(m) {
      @include grid-item(2, -2, "desktop");

      margin-top: 0;
    }

    p {
      margin: get-baseline(1) 0 get-baseline(2) 0;
    }
  }
}
