.o-intro-teaser {
  @include grid-container;

  position: relative;
  z-index: 1;
  color: get-color("white");

  &__wrapper {
    @include grid-item(1, -1);

    background: get-color("black");

    @include mq(m) {
      @include grid-item(2, -2, "desktop");
    }

    &::after {
      @include default-box-shadow;

      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 0.3s ease;
      width: 100%;
      z-index: -1;
    }
  }

  &__ticket-list {
    @include grid-container;

    @include mq(m) {
      @include grid-container(12);
    }

    .o-ticket-list {
      @include grid-item(2, -2);

      margin: get-baseline(10) 0;

      @include mq(m) {
        @include grid-item(2, 11);
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__head {
    img {
      width: 100%;
    }
  }

  &__textbox {
    @include grid-container;

    background: get-color("black");
    color: get-color("white");
    padding: get-baseline(10) 0;

    @include mq(m) {
      @include grid-container(12);
    }

    &__wrapper {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(2, 11);
      }
    }
  }

  &__statuslane {
    display: none;
  }
}
