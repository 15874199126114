.o-modal {
  background-color: transparent;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.25s ease-out;
  z-index: 9999;

  &--is-active {
    background-color: rgba(#000, 0.5);
  }

  &__wrapper {
    @include grid-container;

    grid-auto-rows: 1fr auto 1fr;
    height: 100%;
    margin: 0 auto;
  }

  &__spacer {
    @include grid-item;
  }

  &__content {
    @include grid-item(2, -2);

    background: get-color("grey-dark");
    color: get-color("white");
    opacity: 0;
    padding: get-baseline(5);
    position: relative;
    transform: scale(0.9);
    transition: all 0.25s ease-out 0.15s;

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }
  }

  &--is-active &__content {
    opacity: 1;
    transform: scale(1);
  }

  &__close {
    position: absolute;
    right: get-baseline(2);
    top: get-baseline(2);
  }

  &__title {
    margin-bottom: get-baseline(2);
    padding-right: get-baseline(3);
    display: block;
  }
}
