.t-street-gigs {
  .o-content-blocks__block {
    &--artists-overview {
      background: get-color("grey");
      padding-bottom: get-baseline(6);
    }

    &--gallery .m-gallery {
      padding: 0;
    }

    &:last-child {
      margin-bottom: get-baseline(8);
    }
  }

  .o-teaser-rows__row:first-child {
    margin-top: 0;
  }
}
