.m-tag-list {
  ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  &__hl {
    margin-bottom: get-baseline(1);
  }

  &--vertical ul {
    flex-direction: column;
  }

  &__item {
    margin-bottom: 10px;
    margin-right: get-baseline(1);

    @include mq(m) {
      flex-shrink: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &[aria-hidden="true"] {
      display: none;
    }
  }

  &__toggle {
    display: flex;
    appearance: none;
    border: none;
    margin: get-baseline(3) 0 0;
    padding: 0;
    background: none;
    outline: none;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
      color: get-color("magenta");
    }

    svg {
      width: get-baseline(2);
      height: get-baseline(2);
      margin-left: get-baseline(1);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__show-less {
    display: none;
  }

  &.unfolded {
    .m-tag-list__show-more {
      display: none;
    }

    .m-tag-list__show-less {
      display: block;
    }

    .m-tag-list__toggle svg {
      transform: rotate(180deg);
    }
  }
}
