.t-tag-page {
  position: relative;

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__stage {
    margin-top: get-baseline(5);

    @include mq(m) {
      margin-top: get-baseline(7);
    }
  }
}
