.o-artists-overview {
  position: relative;
  padding: get-baseline(6) 0;

  &__content,
  &__artists {
    position: relative;
    z-index: 1;

    @include grid-container;

    grid-row-gap: get-baseline(6);
    margin: 0 auto;
  }

  h2 {
    hyphens: auto;

    @include grid-item(2, -2);

    @include mq(l) {
      @include grid-item(3, -5);
    }
  }

  &__text {
    @include grid-item(3, -2);

    @include mq(l) {
      @include grid-item(4, -5);
    }
  }

  &__list {
    padding: get-baseline(6) 0 0;
    grid-row-gap: get-baseline(3);

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-container(2);
    }

    @include mq(l) {
      @include grid-container(3);
    }
  }

  &__item {
    overflow: hidden;
    padding-bottom: 56.25%; // aspect ratio ~ 16 x 9
    position: relative;
    background: get-color("grey-dark");
    margin-bottom: get-baseline(3);

    @include mq(m) {
      margin-bottom: 0;
    }

    img {
      inset: 0;
      width: 100%;
      position: absolute;
    }
  }

  &__background-image {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;

    &::before {
      @include gradient;

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.3;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--two-columns &__list {
    @include mq(l) {
      @include grid-item(4, -4);
      @include grid-container(2);
    }
  }
}
