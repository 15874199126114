.a-text {
  margin: 0;
  font-style: normal;

  &--headline-xlarge {
    @include font-headline-xlarge;
  }

  &--headline-large {
    @include font-headline-large;
  }

  &--headline-medium {
    @include font-headline-medium;
  }

  &--headline-small {
    @include font-headline-small;
  }

  &--copy {
    @include font-copy;
  }

  &--copy-small {
    @include font-copy-small;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--hyphenate {
    hyphens: auto;
  }

  &--outline {
    @include font-headline-xlarge-outline;
  }

  b,
  strong,
  &--bold {
    font-weight: get-weight("bold");
  }

  &--extra {
    font-weight: get-weight("extra");
  }

  &--regular {
    font-weight: get-weight("regular");
  }

  i,
  em,
  &--italic {
    font-style: italic;
  }
}
