.a-lane-headline {
  $this: &;

  color: get-color("white");
  text-decoration: none;
  transition: all 0.3s ease;

  &[href] {
    &:hover {
      color: get-color("magenta");
    }
  }

  &__text {
    display: block;
  }

  &__icon {
    display: inline;
    white-space: nowrap;

    svg {
      margin-left: get-baseline(1);
      display: inline-block;
    }
  }
}
