.o-highlight-video-teaser {
  @include grid-container();

  grid-template-rows: min-content 1fr;
  margin: 0 auto;
  grid-row-gap: get-baseline(4);

  &__headline-wrapper {
    display: flex;
    flex-direction: column;
    gap: get-baseline(2);
    grid-row: 1;

    @include grid-item(2, 7);

    @include mq(m) {
      @include grid-item(2, 13);
    }

    @include mq(l) {
      align-self: start;

      @include grid-item(2, 5);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: get-baseline(2);
    grid-row: 3;

    @include grid-item(2, 7);

    @include mq(m) {
      @include grid-item(3, 13);
    }

    @include mq(l) {
      align-self: start;
      grid-row: 2;

      @include grid-item(2, 5);
    }
  }

  &__logo-wrapper {
    max-width: 100px;
  }

  &__logo {
    @include ratio-container(16, 9);
  }

  &__headline {
    max-width: 650px;

    & > span {
      margin-bottom: get-baseline(3);
    }
  }

  &__intro {
    max-width: 520px;
  }

  &__image,
  &__video {
    grid-row: 2;

    @include grid-item(2, 7);

    @include mq(m) {
      @include grid-item(3, 13);
    }

    @include mq(l) {
      grid-row: 1 / span 2;
      align-self: start;

      @include grid-item(6, 13);
    }
  }

  &__video {
    background: get-color("grey-medium");
    aspect-ratio: 16 / 9;
  }

  &__image {
    position: relative;

    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: auto;
    }

    img:first-of-type,
    img:last-of-type {
      position: absolute;
      top: 0;
      opacity: 0.2;
      z-index: -1;
      filter: blur(20px);
    }

    img:first-of-type {
      right: 100%;
    }

    img:last-of-type {
      left: 100%;
      transform: scaleX(-1);
    }
  }

  &--right &__headline-wrapper,
  &--right &__content {
    @include mq(l) {
      @include grid-item(10, 13);
    }
  }

  &--right &__image {
    @include mq(l) {
      @include grid-item(2, 9);
    }
  }
}
