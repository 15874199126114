// inner padding of social wall plugin https://www.anythingabout.de/
$iframe-inner-padding: 20px;

.m-social-wall {
  @include grid-container;

  width: 100%;

  &__text-wrapper {
    padding-top: get-baseline(13);
    margin-bottom: get-baseline(4);

    h2 {
      margin-bottom: get-baseline(3);
    }

    @include mq($until: m) {
      @include grid-item(2, -2, "mobile");
    }

    @include mq(m) {
      @include grid-item(4, -4, "desktop");
    }
  }

  &__socials {
    margin-top: get-baseline(8);
  }

  &__socials-text {
    margin: 0 get-baseline(3) get-baseline(5) 0;
  }

  &__socials-items {
    .m-social-list {
      justify-content: flex-start;

      .m-social-list__item {
        &:not(:last-child) {
          margin-right: get-baseline(3);
        }
      }
    }
  }

  &__iframe-wrapper {
    @include grid-item(1);
  }
}
