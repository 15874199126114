.a-background-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 70vw;
  pointer-events: none;

  svg {
    width: 100%;
    height: auto;
  }
}
