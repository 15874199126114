.o-teaser-slider {
  position: relative;
  margin-bottom: -$box-shadow-substraction;

  @include grid-container;

  &__wrapper {
    @include grid-item(2, -2);
  }

  &__slide-item {
    display: inline-block;
    position: relative;
    padding-bottom: $box-shadow-substraction;
    opacity: 0.2;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  &__controls {
    bottom: get-baseline(5);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - #{$box-shadow-substraction} / 2));
    transition: opacity 0.3s ease;
    width: 100%;

    [data-whatinput="mouse"] &,
    [data-whatinput="touch"] & {
      outline: 0;
    }
  }

  &__prev-button {
    left: 0;
    transform: translate(-100%, -50%);
  }

  &__next-button {
    right: 0;
    transform: translate(100%, -50%);
  }

  &__prev-button,
  &__next-button {
    position: absolute;
    top: 50%;
    transition: opacity 0.3s ease, transform 0.3s ease;

    &:not([disabled]) {
      pointer-events: all;
    }

    &[disabled] {
      opacity: 0;
    }

    &:active,
    &:focus {
      background-color: get-color("black");
    }

    &:hover {
      background-color: get-color("magenta");
    }
  }

  [data-whatintent="mouse"] &:hover &__prev-button {
    transform: translate(get-baseline(5), -50%);
  }

  [data-whatintent="mouse"] &:hover &__next-button {
    transform: translate(get-baseline(-5), -50%);
  }

  [data-whatintent="mouse"] &:hover &__prev-button:hover {
    transform: translate(calc(#{get-baseline(5)} - 2px), -50%);
  }

  [data-whatintent="mouse"] &:hover &__next-button:hover {
    transform: translate(calc(#{get-baseline(-5)} + 2px), -50%);
  }

  [data-whatintent="mouse"] &:hover &__controls {
    opacity: 1;
  }

  // tns overwrites
  & .tns-outer {
    button {
      display: none;
    }

    .tns-ovh {
      overflow: visible;
    }

    .tns-slide-active {
      z-index: 1;
      pointer-events: all;
      opacity: 1;
    }
  }
}
