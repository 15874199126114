@keyframes loadingPlaceholder {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.o-teaser-grid {
  &__wrapper {
    @include grid-container;

    margin: 0 auto;
  }

  &__header {
    @include grid-item(2, -2);

    margin-bottom: get-baseline(10);

    @include mq(l) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__teasers {
    @include grid-item(2, -2);

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: get-baseline(3);
    grid-row-gap: get-baseline(3);

    @include mq(m) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(l) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-row-gap: get-baseline(7);
    }
  }

  &__load-more {
    @include grid-item(2, -2);

    display: flex;
    justify-content: center;
    margin-top: get-baseline(10);
  }

  &__loading {
    animation: loadingPlaceholder 1.8s infinite forwards linear;
    background: get-color("grey");
    background: linear-gradient(
      to right,
      get-color("grey") 8%,
      get-color("grey-light") 38%,
      get-color("grey") 54%
    );
    opacity: 0.3;
    background-size: 1000px 640px;
  }

  &__loading-image {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__loading-headline {
    width: 80%;
    height: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  &__loading-status {
    width: 40%;
    min-width: 200px;
    margin-top: 3px;
    height: 18px;
  }

  &__categories {
    margin-top: get-baseline(2);

    @include mq(m) {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-self: flex-end;
    }

    p {
      margin-right: get-baseline(2);
      margin-top: get-baseline(1);
      flex-grow: 2;
    }

    button {
      margin-right: get-baseline(1);

      &:last-of-type {
        margin-right: 0;
      }
    }

    &--placeholder {
      min-height: get-baseline(6);
    }
  }

  &__select {
    position: relative;

    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      padding-right: get-baseline(4);
    }

    svg {
      position: absolute;
      width: 24px;
      height: 24px;
      right: get-baseline(1);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
