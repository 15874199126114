.m-social-list {
  // TODO one day, when Safari <14.5 no longer matters, we can switch to `gap: var(--row-gap) var(--col-gap);`
  --row-gap: #{get-baseline(3)};
  --col-gap: #{get-baseline(5)};

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: calc(-1 * var(--row-gap) / 2) calc(-1 * var(--col-gap) / 2);

  @include mq(m) {
    --col-gap: #{get-baseline(11)};
  }

  &--no-borders {
    --col-gap: #{get-baseline(3)};

    @include mq(m) {
      --col-gap: #{get-baseline(3)};
    }
  }

  &__item {
    margin: calc(var(--row-gap) / 2) calc(var(--col-gap) / 2);
  }
}
