:root {
  --mm-header-height: 50px;
  --mm-search-bar-height: #{get-baseline(12)};

  @include mq(m) {
    --mm-header-height: 80px;
    --mm-search-bar-height: #{get-baseline(17)};
  }

  --theme-default: #{get-color("grey-dark")};
  --theme-background: var(--theme-default);
}

* {
  &:focus {
    @include focus-global;
  }
}

body {
  font-family: $font-magenta-musik;
  background-color: var(--theme-background);
  color: get-color("white");
  width: 100%;

  // Text selection
  ::selection {
    @include selection-global;
  }

  &.overflow--hidden {
    height: 100%;
    overflow: hidden;
    position: fixed;

    @include mq(m) {
      position: relative;
    }
  }
}

// To avoid conflicts with the play button of the video player, we exclude the corresponding image from the following rule (@see https://jira.fork.de/browse/MRR-71)
img:not([class^="playStream_icon"]) {
  display: block;
}

main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - var(--mm-header-height));
  width: 100%;
  position: relative;
}
