.a-tag {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 get-baseline(1);
  background: get-color("white");
  color: get-color("black");
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: get-baseline(3);

  @include mq(m) {
    min-height: get-baseline(4);
  }

  span {
    white-space: nowrap;
    line-height: 1;
  }

  &--large {
    padding: get-baseline(1) get-baseline(2);
    background: get-color("white");
  }
}

button.a-tag {
  appearance: none;
  border: none;
  outline: none;
  margin-top: get-baseline(1);
  cursor: pointer;
}

a.a-tag:hover,
a.a-tag:focus,
.a-tag.selected {
  background: get-color("magenta");
  color: get-color("white");
}
