.m-livestream-teaser {
  z-index: 1;
  display: grid;
  grid-template:
    "h"
    "c"
    "i";
  column-gap: get-baseline(3);

  @include mq(m) {
    grid-template:
      "h h h h h h"
      "c i i i i i" / 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__countdown,
  &__live-headline {
    display: flex;
    grid-area: h;
    z-index: 1;

    // The font already has ~11px space below the baseline
    margin-bottom: get-baseline(1.5);

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &--is-live &__countdown {
    display: none;
  }

  &__live-headline {
    display: none;
  }

  &--is-live &__live-headline {
    display: flex;
  }

  &--is-live-stream &__image {
    display: none;
  }

  &__live-indicator {
    @keyframes blinker {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(get-color("magenta"), 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 0, 68, 0%);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 0, 68, 0%);
      }
    }

    animation: blinker 2s infinite;
    background: get-color("magenta");
    border-radius: 100%;
    box-shadow: 0 0 0 0 get-color("magenta");
    height: get-baseline(3);
    margin-left: get-baseline(2);
    margin-top: 5px;
    width: get-baseline(3);

    @include mq(m) {
      height: get-baseline(4);
      width: get-baseline(4);
    }
  }

  &__image,
  &__video {
    aspect-ratio: 16 / 9;
    grid-area: i;
    width: 100%;
  }

  &__content {
    align-items: center;
    display: flex;
    gap: get-baseline(3);
    grid-area: c;
    margin-bottom: get-baseline(3);

    button {
      flex-shrink: 0;
    }

    @include mq(m) {
      align-items: flex-start;
      flex-direction: column;
      margin-top: get-baseline(3);
      margin-bottom: 0;
    }
  }
}
