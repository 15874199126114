.o-article-stage {
  @include grid-container;

  grid-row-gap: get-baseline(4);
  margin-bottom: get-baseline(4);
  position: relative;

  &__image {
    @include grid-item(2, -2);
    @include ratio-container(9, 5);

    @include mq(m) {
      @include grid-item(3, -3);
    }

    @include mq(l) {
      @include grid-item(4, -4);
    }
  }

  &__logo {
    display: flex;
    height: get-baseline(14);
    justify-content: center;
    position: absolute;
    top: get-baseline(3.5);
    width: 100%;

    @include mq(m) {
      height: get-baseline(35);
      top: get-baseline(4);
    }

    img {
      max-width: calc(100% - #{get-baseline(8)});
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__upright {
    @include grid-item(1, -1);

    margin-top: get-baseline(21);

    @include mq(m) {
      margin-top: get-baseline(43);
    }

    .o-stage-upright__slides-wrapper {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
