/// Pixels added to the size of an icon in a button (even numbers only)
$add-to-icon-size: 4px;

.a-button {
  $this: &;

  align-items: center;
  appearance: none;
  background: none;
  border-radius: 500px;
  height: get-baseline(5);
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  outline: 0;
  padding: 0 get-baseline(2);
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
  user-select: none;
  white-space: nowrap;
  border: 2px solid;

  @include mq(m) {
    height: get-baseline(6);
  }

  &__text {
    @include font-copy;

    font-weight: get-weight("bold");
  }

  &__icon-container {
    align-items: center;
    display: flex;
    height: 28px;
    width: 28px;

    &--center {
      justify-content: center;
    }

    &--left {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: get-baseline(2);
    }

    &--right {
      justify-content: flex-start;
      margin-left: get-baseline(2);
      margin-right: 0;
    }

    &--reduced {
      height: calc(#{get-baseline(2)} + #{$add-to-icon-size});
      width: calc(#{get-baseline(2)} + #{$add-to-icon-size});
    }
  }

  &__icon {
    max-height: 100%;
    max-width: 100%;

    &--active {
      display: none;
    }
  }

  &--active & {
    &__icon {
      display: none;

      &--active {
        display: block;
      }
    }
  }

  &--primary {
    background-color: get-color("magenta");
    color: get-color("white");
    border-color: get-color("magenta");

    &:hover,
    &:focus,
    .has-hoverable-children:hover &,
    .has-hoverable-children:focus & {
      background-color: get-color("white");
      color: get-color("magenta");
      border-color: get-color("white");
    }
  }

  &--secondary {
    background-color: transparent;
    color: get-color("white");
    border-color: get-color("white");

    &:hover,
    &:focus,
    .has-hoverable-children:hover &,
    .has-hoverable-children:focus & {
      background-color: transparent;
      border-color: get-color("magenta");
      color: get-color("magenta");
    }
  }

  &--ghost {
    background-color: transparent;
    color: get-color("white");
    border-color: transparent;

    &:hover,
    &:focus,
    .has-hoverable-children:hover &,
    .has-hoverable-children:focus & {
      background-color: transparent;
      color: get-color("magenta");
      border-color: transparent;
    }
  }

  &[disabled],
  &--disabled {
    cursor: not-allowed;
    background-color: get-color("grey-light");
    color: get-color("white");
    border-color: get-color("grey-light");
  }

  &--white {
    background-color: transparent;
    color: get-color("white");
    border-color: get-color("white");

    &:hover,
    &:focus,
    .has-hoverable-children:hover &,
    .has-hoverable-children:focus & {
      background-color: get-color("white");
      color: get-color("magenta");
      border-color: get-color("white");
    }
  }

  &--icon-only {
    padding: 0;
    height: get-baseline(5);
    width: get-baseline(5);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(m) {
      height: get-baseline(6);
      width: get-baseline(6);
    }
  }

  &--icon-big &__icon-container {
    width: get-baseline(4);
    height: get-baseline(4);
  }

  &__link-wrapper {
    align-items: center;
    background: get-color("grey");
    border-radius: 25px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: get-baseline(1);
    min-height: get-baseline(7);
    margin-left: get-baseline(-1);
    padding: get-baseline(1) get-baseline(3) get-baseline(1) get-baseline(1);

    @include mq(m) {
      flex-wrap: nowrap;
      height: get-baseline(8);
    }
  }

  &__link-wrapper--compact-right {
    flex-direction: column-reverse;
    padding-right: get-baseline(1);
    text-align: right;

    @include mq(m) {
      flex-direction: row-reverse;
      padding-left: get-baseline(3);
    }
  }

  &__link-wrapper-label {
    max-height: 100%;
    margin-left: get-baseline(1) !important;
  }
}
