.o-collection-tabs {
  @include grid-container;

  margin: 0 auto;

  &__list {
    @include grid-item(2, -2);

    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: get-baseline(3);
    grid-row-gap: get-baseline(4);

    @include mq(s) {
      grid-row-gap: get-baseline(4);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(l) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__tab {
    cursor: pointer;
    align-items: center;
    appearance: none;
    background-color: get-color("grey");
    border: none;
    border-radius: 5px;
    display: flex;
    height: 34px;
    justify-content: center;
    padding: get-baseline(1) get-baseline(3);
    position: relative;
    grid-column: span 1;

    @include mq(m) {
      height: 60px;
      padding: 0 get-baseline(4);
    }

    &[aria-hidden="true"] {
      display: none;
    }

    &:hover {
      border-color: color(neutral);
      background-color: get-color("magenta");
      box-shadow: 0 0 10px 0 get-color("magenta");
      color: color(primary);
    }

    &[aria-selected="true"],
    &:focus {
      background-color: get-color("magenta");
      box-shadow: 0 0 10px 0 get-color("magenta");
      outline: none;
    }
  }

  &__tab-logo {
    height: get-baseline(3);

    @include mq(m) {
      height: get-baseline(4);
    }
  }

  &--centered &__list {
    justify-content: center;
  }

  &__panel {
    @include grid-item(1, -1);

    margin-top: get-baseline(12);
  }
}
