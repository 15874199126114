/// Default input styles
/// @param {Boolean} $with-font [true] Disable this to get the default input styles without font definitions (used for checkboxes)
:root {
  --mm-input-background-color: #{get-color("white")};
  --mm-input-border-color: transparent;
  --mm-input-color: #{get-color("grey-dark")};
  --mm-input-placeholder-color: #{get-color("grey")};
}

@mixin default-input-styles($with-font: true) {
  @if $with-font {
    @include font-copy;
  }

  background-color: var(--mm-input-background-color);
  border-color: var(--mm-input-border-color);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  color: var(--mm-input-color);
  display: block;
  padding: get-baseline(1) get-baseline(2) get-baseline(1);
  transition: all 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &::placeholder {
    color: var(--mm-input-placeholder-color);
  }

  &:focus,
  &:hover {
    @include focus-global-big("magenta");
  }
}

.a-input {
  $this: ".a-input";
  $element: ".a-input__element";
  $pseudoElement: ".a-input__element--pseudo";
  $label: ".a-input__label";

  display: flex;
  flex-direction: column-reverse;
  margin-bottom: get-baseline(4);

  // Error message
  &__error {
    color: get-color("red");
    display: block;
    margin: get-baseline(1) 0;
    font-weight: get-weight("bold");
  }

  // Label
  #{$label} {
    cursor: pointer;
    display: block;

    &__text {
      display: inline-block;
      margin-bottom: get-baseline(1);
    }
  }

  // The real form element (<input>, <textarea>)
  #{$element},
  #{$pseudoElement} {
    &__wrapper {
      position: relative;
    }
  }

  &:not(&--type-checkbox):not(&--type-radio) {
    #{$element},
    #{$pseudoElement} {
      @include default-input-styles;

      width: 100%;
    }
  }

  // Valid
  &--valid {
    --mm-input-border-color: #{get-color("green")};
  }

  // Warning
  &--warning {
    --mm-input-border-color: #{get-color("yellow")};
  }

  // Invalid
  &--invalid {
    --mm-input-border-color: #{get-color("red")};
  }

  // Disabled
  &--disabled {
    --mm-input-background-color: #{rgba(get-color("white"), 0.6)};
    --mm-input-border-color: currentcolor;
    --mm-input-color: #{get-color("grey-light")};
    --mm-input-placeholder-color: currentcolor;

    #{$element},
    #{$pseudoElement} {
      cursor: not-allowed;
      resize: none;
    }

    #{$label} {
      cursor: not-allowed;
    }
  }

  // Readonly
  &--readonly {
    --mm-input-background-color: none;
    --mm-input-border-color: transparent;
    --mm-input-color: #{get-color("white")};

    #{$element},
    #{$pseudoElement} {
      &:focus,
      &:hover {
        box-shadow: none !important;
      }
    }
  }

  &--hidden-label &__label__text {
    @include hide-visually;
  }

  &--type-checkbox,
  &--type-radio {
    #{$label} {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &__text {
        display: block;
        margin: 0;
      }
    }

    // hide real input
    input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: -1;

      &:checked {
        + #{$pseudoElement} #{$element}--pseudo__icon {
          opacity: 1;
        }
      }
    }

    #{$pseudoElement} {
      @include default-input-styles(
        false
      ); // We don't want typography styles on checkboxes and radios so we set the parameter $with-font to false

      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: get-baseline(2);
      padding: 0;
      position: relative;
      width: get-baseline(4);
      height: get-baseline(4);
    }

    #{$element}--pseudo__icon {
      fill: get-color("black");
      opacity: 0;
      transition: 0.2s ease all;
      width: get-baseline(2);
      height: get-baseline(2);
    }

    // handle focus
    #{$element}:focus,
    #{$element}:hover {
      + #{$pseudoElement} {
        @include focus-global-big("magenta");
      }
    }
  }

  &--type-radio {
    #{$pseudoElement} {
      border-radius: 50%;
    }

    // there is no icon for "radio" in the iconfont so we just build a circle with css
    #{$element}--pseudo__icon {
      background-color: get-color("magenta");
      border-radius: 50%;
      display: block;
      height: get-baseline(2);
      width: get-baseline(2);
    }
  }

  &--type-hidden {
    @include hide-visually;
  }

  &--type-captcha {
    margin: 0;
  }
}
