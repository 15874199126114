.o-video-story {
  @include grid-container(12);

  row-gap: get-baseline(4);

  @include mq(l) {
    @include grid-container(11);
  }

  .m-mixed-copy {
    grid-column: span 12;

    @include mq(l) {
      grid-column: 1 / span 7;
    }
  }

  &__video {
    --ratio: 9 / 16;

    aspect-ratio: var(--ratio);
    grid-column: span 12;
    width: 100%;

    @include mq(m) {
      grid-column: 5 / span 4;
    }

    @include mq(l) {
      grid-column: 9 / span 3;
    }

    video {
      width: 100%;
      height: 100%;
      aspect-ratio: var(--ratio);
      background: get-color("grey-medium");
    }
  }
}
