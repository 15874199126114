.m-nav-trigger {
  display: flex;

  &__button {
    &:not(:first-of-type) {
      margin-left: get-baseline(1);
    }

    &:hover,
    &:focus {
      color: get-color("white");
    }
  }
}
