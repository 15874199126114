.m-product-teaser {
  @include grid-container;

  &__wrapper {
    @include grid-item(1);

    @include mq(m) {
      @include grid-item(2, -2, "desktop");
    }
  }

  &__inner-wrapper {
    background-color: get-color("black");
    position: relative;

    &::before {
      content: "";
      display: block;

      // Teaser has different aspect ratios depending on screen size
      padding-top: calc(3 / 2 * 100%);

      @include mq(640px) {
        padding-top: calc(1 / 2 * 100%);
      }

      @include mq(1024px) {
        padding-top: calc(9 / 27 * 100%);
      }
    }

    // Box Shadow of the teaser
    &::after {
      @include default-box-shadow;

      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 0.3s ease;
      width: 100%;
      z-index: -1;
    }
  }

  &__background {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;

    > .a-picture {
      height: 100%;
      position: relative;
      width: 100%;
    }

    > .a-picture > .a-picture__image {
      height: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      // Get rid of rounding errors which causes white
      // lines in edge

      width: calc(100% + 2px);
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }

  &__content {
    padding: get-baseline(5);
    width: 100%;

    @include mq(m) {
      padding: get-baseline(7);
    }
  }

  &__svg {
    max-width: get-baseline(20);
    max-height: get-baseline(20);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    @include font-headline-large;

    &--white {
      color: get-color("white");
    }

    &--magenta {
      color: get-color("magenta");
    }

    &--black {
      color: get-color("black");
    }
  }

  &__copy {
    @include font-headline-medium;

    margin-bottom: get-baseline(5);

    &--white {
      color: get-color("white-dark");
    }

    &--magenta {
      color: get-color("magenta");
    }

    &--black {
      color: get-color("black");
    }
  }

  &__logo {
    display: block;
  }

  &__title,
  &__svg,
  &__logo {
    margin-bottom: get-baseline(3);
  }

  @for $i from 0 through 10 {
    &--content-width-#{50 + $i * 5} &__content {
      padding-left: 0;
      padding-right: 0;
      width: #{50% + $i * 5%};
    }
  }

  &--content-pos-bottom &__inner {
    justify-content: flex-end;
  }

  &--content-pos-center &__inner {
    justify-content: center;
  }

  &--context-color-light {
    background: get-color("white");
  }

  &--context-color-light &__copy {
    color: get-color("black");
  }

  &--context-color-light &__headline {
    color: get-color("black");
  }
}
