/* Intro Animation Timeline:

   -   0s: Button is not visible
   - 0.3s: Button has finished sliding in
   - 0.9s: Button transitions into hover state
   - 4.9s: Button transitions into default state

   The hover button state is visible for four seconds so there is
   enough time for the user to read the text.
 */
@keyframes m-feedback-btn-intro {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  // after 0.3s (6% = 0.3s / 4.9s * 100)
  6% {
    opacity: 1;
    transform: translateY(0);
  }

  // after 0.3s + 0.6s + 4s = 4.9s
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.m-feedback-btn {
  /**
   * transition (shared by all elements of the feedback-btn)
   */
  --transition: all 0.3s ease;

  /**
   * animation-delay
   *
   * *** This value controls the delay until the button slides in! ***
   */
  --delay: 60s;

  appearance: none;
  background: none;
  border: 0;
  bottom: get-baseline(3);
  cursor: pointer;
  position: fixed;
  right: get-baseline(3);
  transform: translateY(calc(100% + #{get-baseline(3)}));
  z-index: 900;

  @include mq(m) {
    bottom: get-baseline(1);
    right: get-baseline(8);
    transform: translateY(calc(100% + #{get-baseline(1)}));
  }

  &--animate {
    animation: m-feedback-btn-intro 4.9s forwards;
    animation-delay: var(--delay);
  }

  &--animate:not(#{&}--animationend) * {
    transition-delay: calc(var(--delay) + 0.9s);
  }

  &__inner {
    /**
     * padding-top and padding-bottom
     */
    --py: #{get-baseline(1)};

    /**
     * padding-left and padding-right
     */
    --px: #{get-baseline(2)};

    /**
     * Content width, without padding
     */
    --w: 65px;

    /**
     * Content height, without padding
     */
    --h: #{get-baseline(3)}; // line-height of &__text

    align-items: center;
    background-color: get-color("magenta");
    border-radius: 20px 20px 20px 0;
    color: get-color("white");
    display: flex;
    flex-direction: column;
    height: calc(var(--h) + 2 * var(--py));
    overflow: hidden;
    padding: var(--py) var(--px);
    transform-origin: bottom left;
    transform: translate(var(--translate-x, 0), var(--translate-y, 0))
      rotate(var(--rotate, 0));
    transition: var(--transition);
    white-space: nowrap;
    width: calc(var(--w) + 2 * var(--px));
    will-change: transform, width, height;

    @include mq(m) {
      --w: 80px;
      --h: #{get-baseline(4)}; // line-height of &__text
    }
  }

  &--overlaps-footer &__inner {
    @include mq(m) {
      --translate-y: calc(-1 * var(--mm-footer-height));
    }
  }

  &:hover &__inner,
  &--animate:not(#{&}--animationend) &__inner {
    --w: 80px;
    --h: #{get-baseline(2 + 3)}; // line-height of &__subline and &__text
    --translate-x: 15px;
    --rotate: -5deg;

    @include mq(m) {
      --w: 110px;
      --h: #{get-baseline(3 + 4)}; // line-height of &__subline and &__text
      --translate-x: 30px;
    }
  }

  &__subline {
    /**
     * transform: translateY(...)
     */
    --ty: 5px;

    @include font-copy-small;

    display: block;
    opacity: 0;
    transform: translateY(var(--ty));
    transition: var(--transition);
    will-change: opacity, transform;
  }

  &:hover &__subline,
  &--animate:not(#{&}--animationend) &__subline {
    --ty: 0;

    opacity: 1;
  }

  &__text {
    /**
     * transform: translateY(...)
     */
    --ty: -16px;

    @include font-copy;

    display: block;
    font-weight: get-weight("bold");
    transform: translateY(var(--ty));
    transition: var(--transition);
    will-change: transform;

    @include mq(m) {
      --ty: #{-1 * get-baseline(3)}; // line-height of &__subline
    }
  }

  &:hover &__text,
  &--animate:not(#{&}--animationend) &__text {
    --ty: 0;
  }
}
