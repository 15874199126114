.t-article-page {
  position: relative;

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__background-image {
    width: 100%;
    position: relative;
    height: 35vh;
    margin-top: get-baseline(-3);
    margin-bottom: calc(-35vh + #{get-baseline(3)});
    opacity: 1;
    background-size: cover;
    background-position: center 0%;

    @include mq(m) {
      height: 50vh;
      margin-bottom: calc(-50vh + #{get-baseline(3)});
    }

    @include mq(l) {
      height: 500px;
      margin-bottom: calc(-500px + #{get-baseline(3)});
    }

    &__overlay {
      background-image: linear-gradient(transparent, var(--theme-background));
      bottom: -1px;
      height: 50%;
      position: absolute;
      width: 100%;
    }
  }

  &__stage {
    margin-top: get-baseline(5);

    @include mq(m) {
      margin-top: get-baseline(7);
    }
  }

  &__intro {
    @include grid-item(1);

    margin: 0 0 get-baseline(10) 0;
    z-index: 1;

    .m-page-intro--no-image {
      margin-top: get-baseline(5);

      @include mq(m) {
        margin-top: get-baseline(7);
      }
    }
  }

  &__title {
    margin: get-baseline(20) 0 0;

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }
  }
}
