.o-stage-street-gigs {
  @include grid-container;
  @include grid-size("--column-width", "desktop", 1);

  &__countdown {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(2, 6);

      margin-left: calc(0.5 * var(--column-width));
    }
  }

  &__image {
    grid-row: 2;
    grid-row-gap: get-baseline(4);
    margin-bottom: get-baseline(6);
    opacity: 0.5;

    @include ratio-container(16, 9);
    @include grid-item(1, -1);

    @include mq(m) {
      grid-row: 1;

      @include grid-item(4, 12);
    }
  }

  &__wrapper {
    @include grid-item(1, -1);
    @include grid-container;

    grid-row: 2;
    z-index: 1;
    margin-top: get-baseline(16);
    margin-bottom: get-baseline(5);
    grid-row-gap: get-baseline(4);

    @include mq(m) {
      grid-row: 1;
    }
  }

  &__headline {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(2, 6);
    }

    h1 {
      margin-bottom: get-baseline(9);

      span {
        @include hide-visually;
      }
    }
  }

  &__logo {
    display: block;
    width: get-baseline(26);
    height: auto;
    padding-left: get-baseline(4);

    @include mq(m) {
      margin-left: calc(0.5 * var(--column-width));
      padding-left: 0;
    }
  }

  &__intro {
    @include grid-item(2, 6);

    padding-left: get-baseline(4);

    @include mq(m) {
      @include grid-item(2, 6);

      margin-left: calc(0.5 * var(--column-width));
      padding-left: 0;
    }
  }

  &__button-bundle {
    @include grid-item(2, 6);

    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    gap: get-baseline(2);
    padding-left: get-baseline(4);

    @include mq(m) {
      @include grid-item(2, 6);

      margin-left: calc(0.5 * var(--column-width));
      padding-left: 0;
    }
  }

  &__button-row {
    display: flex;
    gap: get-baseline(2);
  }

  &__event {
    @include grid-item(2, 7);

    grid-row: 1;
    margin: get-baseline(5) 0;
    z-index: 2;

    @include mq(m) {
      @include grid-item(8, 13);

      margin-top: get-baseline(38);
    }
  }

  &__info {
    align-items: center;
    color: get-color("white");
    display: flex;

    svg {
      display: block;
      height: get-baseline(3);
      margin-right: get-baseline(1);
      width: get-baseline(3);
    }
  }

  $states: anonymous, authenticated, can-participate, has-participated,
    has-ended;

  @each $state in $states {
    &:not(.state-#{$state}) .state-#{$state} {
      display: none;
    }
  }
}
