.m-infobar {
  &__wrapper {
    display: flex;
    padding: get-baseline(3) 0 get-baseline(8) 0;

    @include mq($until: l) {
      flex-direction: column;
    }

    @include mq(l) {
      flex-wrap: wrap;
    }

    > ul:nth-child(3) {
      @include mq(l) {
        padding-left: get-baseline(6);
      }
    }
  }

  &__categories {
    .m-tag-list > p {
      @include font-copy;

      font-weight: normal;
    }

    @include mq(l) {
      flex-basis: 50%;
    }
  }

  &__info-content {
    margin-top: get-baseline(4);

    @include mq(m) {
      margin-top: get-baseline(8);
    }

    @include mq(l) {
      flex-basis: 50%;
      margin-top: get-baseline(5);
    }

    &__item {
      display: flex;
      margin-bottom: get-baseline(2);
      margin-right: get-baseline(2);

      &__prop {
        color: get-color("grey-light");
        padding-right: get-baseline(1);
        padding-top: 5px;
        flex: 0 0 50px;
        text-transform: uppercase;
      }

      &__val {
        color: get-color("white");
        flex: 0 0 100%;
      }
    }
  }

  &__social {
    display: flex;
    gap: get-baseline(1);
    margin-top: get-baseline(4);

    @include mq(m) {
      gap: get-baseline(3);
      margin-top: get-baseline(8);
    }
  }

  &--mobile-hidden {
    @include mq($until: l) {
      display: none;
    }
  }

  &--mobile-only {
    @include mq(m) {
      display: none;
    }
  }
}
