.o-ticket-faq {
  background: get-color("black");
  border-radius: get-baseline(1);

  @include grid-container;

  @include mq(m) {
    @include grid-container(10);
  }

  @include mq(l) {
    @include grid-container(8);
  }

  &__copy *:first-child {
    margin-top: 0;
  }

  &__content {
    padding: get-baseline(3);
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 2 / -2;
      padding: get-baseline(7) 0;
    }
  }

  &__faqs {
    margin-bottom: get-baseline(5);

    @include mq(m) {
      margin-left: -34px;
    }
  }

  &__cta {
    margin-top: get-baseline(3);
  }

  details {
    margin-top: get-baseline(3);

    @include mq(m) {
      margin-top: get-baseline(4);
    }
  }

  summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    list-style: none;

    @include font-copy;

    font-weight: get-weight("bold");

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__faq-summary {
    position: relative;
    padding-left: 34px;
  }

  &__faq-icon {
    width: 20px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 4px;

    @include mq(m) {
      top: 6px;
    }
  }

  &__faq-content {
    @include font-copy;

    margin-top: get-baseline(1);
    padding-left: 34px;

    a {
      text-decoration: underline;
    }

    & > * + * {
      margin-top: get-baseline(1);
    }
  }

  details[open] summary {
    .o-ticket-faq__faq-icon {
      transform: rotate(180deg);
    }
  }
}
