@use "sass:math";

/// Color 1/2 for Grid demo

$grid-debug-color-1: red;

/// Color 2/2 for Grid demo

$grid-debug-color-2: blue;

/// Grid configuration map (each value is required)

$grid-configuration: (
  "mobile": (
    "gutter": $baseline-grid-size * 3,
    "columns": 8,
    "spaceAround": 20px,
    "maxWidth": 1680px
  ),
  "desktop": (
    "gutter": $baseline-grid-size * 3,
    "columns": 14,
    "spaceAround": 75px,
    "maxWidth": 1680px
  )
);

/// Grid max width

$maxWidth: map-deep-get($grid-configuration, "desktop", "maxWidth");

/// Get the span of n columns. Note: spaceAround is excluded.
/// @requires {Variable} grid-configuration
/// @param {String} $property CSS property like 'width'
/// @param {String} $breakpoint ['mobile'] Grid breakpoint, one of: "mobile", "desktop"
/// @param {Number} $columns [1] Number of columns
/// @param {Number} $max [100vw] Max width
/// @returns {Number} Size in vw
/// @example
///   .foo {
///     width: grid-size('mobile', 5);
///
///     @include mq(m) {
///       width: grid-size('desktop', 7);
///     }
///   }

@mixin grid-size($property, $breakpoint, $columns: 1, $max: 100vw) {
  $gutter: map-deep-get($grid-configuration, $breakpoint, "gutter");
  $spaceAround: map-deep-get($grid-configuration, $breakpoint, "spaceAround");
  $columnsTotal: map-deep-get($grid-configuration, $breakpoint, "columns") - 2;

  #{$property}:
    calc(
      #{$gutter} * #{$columns - 1} + #{$columns} / #{$columnsTotal} *
      (#{$max} - #{2 * $spaceAround + ($columnsTotal - 1) * $gutter})
    );
}

/// Grid container (CSS-Grid).
/// @link https://caniuse.com/#search=grid
/// @link https://css-tricks.com/snippets/css/complete-guide-grid/.
/// @param {Number} $columns [null] How many columns should the grid have?

@mixin grid-container($columns: null) {
  display: grid;
  grid-auto-rows: max-content;
  grid-column-gap: map-deep-get($grid-configuration, "mobile", "gutter");
  max-width: map-deep-get($grid-configuration, "mobile", "maxWidth");
  width: 100%;

  @if $columns {
    grid-template-columns: repeat($columns, minmax(0, 1fr));
  } @else {
    // Since spaceAround is defined in $grid-configuration we set the size of the first and last column to match the value of spaceAround minus the grid gutter size. By doing this, we can avoid using (negative) margins to create elements that span all columns AND the spaceAround area - what designers love to do.
    $spaceAround: math.max(
      0,
      map-deep-get($grid-configuration, "mobile", "spaceAround") -
      map-deep-get($grid-configuration, "mobile", "gutter")
    );

    grid-template-columns:
      $spaceAround
      repeat(map-deep-get($grid-configuration, "mobile", "columns") - 2, minmax(0, 1fr))
      $spaceAround;
  }

  @include mq(m) {
    grid-column-gap: map-deep-get($grid-configuration, "desktop", "gutter");
    max-width: map-deep-get($grid-configuration, "desktop", "maxWidth");

    @if $columns {
      grid-template-columns: repeat($columns, minmax(0, 1fr));
    } @else {
      // Since spaceAround is defined in $grid-configuration we set the size of the first and last column to match the value of spaceAround minus the grid gutter size. By doing this, we can avoid using (negative) margins to create elements that span all columns AND the spaceAround area - what designers love to do.
      $spaceAround: math.max(
        0,
        map-deep-get($grid-configuration, "desktop", "spaceAround") -
        map-deep-get($grid-configuration, "desktop", "gutter")
      );

      grid-template-columns:
        $spaceAround
        repeat(map-deep-get($grid-configuration, "desktop", "columns") - 2, minmax(0, 1fr))
        $spaceAround;
    }
  }
}

/// Grid item
/// @param {Number} $start [1] Grid column start
/// @param {Number} $end [null] Grid column end. If empty the grid item will use the available space. If the value is negative, the parameter works in reverse to $start.
/// @param {String} $breakpoint [null] One of: 'mobile', 'desktop'
/// @example
///   .container {
///     @include grid-container(6); // columns total: 6
///
///     .item {
///       @include grid-item(); // columns: 1 - 6
///       @include grid-item(3); // columns: 3 - 6
///       @include grid-item(3, 3); // column: 3
///       @include grid-item(3, 5); // columns: 3 - 5
///       @include grid-item(1, -1); // columns: 1 - 6
///       @include grid-item(2, -2); // columns: 2 - 5
///       @include grid-item(3, -3); // columns: 3 - 4
///
///       /* If you want to use a specific overwrite for a specific breakpoint, use this syntax: */
///       @include mq(m) {
///         @include grid-item(2, -6, 'desktop');
///       }
///     }
///   }

@mixin grid-item($start: 1, $end: null, $breakpoint: null) {
  grid-column-start: $start;

  @if $grid-debug {
    outline: 1px dashed green;
    position: relative;

    &::before {
      background-color: green;
      color: white;
      content: "grid-item(#{$start}, #{$end})";
      display: inline-block;
      left: 0;
      opacity: 0.8;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }

  @if $end {
    @if $end > 0 {
      grid-column-end: $end + 1;
    } @else {
      @if $breakpoint {
        grid-column-end: map-deep-get($grid-configuration, $breakpoint, "columns") + 1 + $end + 1;
      } @else {
        grid-column-end: map-deep-get($grid-configuration, "mobile", "columns") + 1 + $end + 1;

        @include mq(m) {
          grid-column-end: map-deep-get($grid-configuration, "desktop", "columns") + 1 + $end + 1;
        }
      }
    }
  } @else {
    @if $breakpoint {
      grid-column-end: map-deep-get($grid-configuration, $breakpoint, "columns") + 1;
    } @else {
      grid-column-end: map-deep-get($grid-configuration, "mobile", "columns") + 1;

      @include mq(m) {
        grid-column-end: map-deep-get($grid-configuration, "desktop", "columns") + 1;
      }
    }
  }
}
