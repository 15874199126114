.t-faqs {
  position: relative;

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__intro {
    grid-column: 2 / -2;

    @include mq(m) {
      grid-column: 2 / -4;
    }
  }

  &__content {
    grid-column: 2 / -2;
    margin-top: get-baseline(6);

    @include mq(m) {
      grid-column: 3 / -4;
      margin-top: get-baseline(12);
    }
  }

  &__links p {
    margin-bottom: get-baseline(3);
  }

  &__link {
    display: flex;
    gap: get-baseline(2);
    align-items: center;
    text-decoration: none;
    margin-top: get-baseline(3);

    @include mq(m) {
      margin-top: get-baseline(5);
    }

    &:hover,
    &:focus {
      color: get-color("magenta");
    }

    &:first-of-type {
      margin-top: 0;
    }

    svg {
      width: get-baseline(4);
      height: get-baseline(4);
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    margin-bottom: get-baseline(11);

    @include mq(m) {
      margin-top: get-baseline(11);
    }
  }

  &__section {
    padding-top: get-baseline(7);

    @include mq(m) {
      padding-top: get-baseline(11);
    }

    &-copy {
      margin-top: get-baseline(3);
    }

    &-accordion {
      margin-top: get-baseline(5);
    }
  }

  &__blocks {
    margin-top: get-baseline(11);
  }
}
