.o-music-finder {
  margin: 0 auto;

  @include grid-container;

  grid-gap: get-baseline(6);

  &__fancy-hl {
    position: relative;
    font-weight: get-weight("extra");
    line-height: 1;
    user-select: none;

    @include grid-item(1, -1);

    @include mq(l) {
      @include grid-item(2, -2);
    }

    span {
      font-size: 72px;
      color: var(--theme-background);
      -webkit-text-stroke: 1px get-color("magenta");
      margin-left: -3px;

      @include mq(m) {
        font-size: 100px;
      }

      @include mq(l) {
        font-size: 130px;
      }
    }

    h2 {
      font-size: 72px;
      top: 20px;
      position: absolute;
      left: 20px;

      @include mq(m) {
        top: 40px;
        left: 40px;
      }

      @include mq(l) {
        top: 70px;
        left: 100px;
      }
    }
  }

  &__hl {
    margin-bottom: get-space(2);

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -2);
    }

    @include mq(l) {
      @include grid-item(4, -4);
    }
  }

  &__categories {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: map-deep-get($grid-configuration, "mobile", "gutter");
    grid-row-gap: get-baseline(4);

    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -2);
    }

    .m-tag-list--vertical ul {
      flex-direction: row;

      @include mq(l) {
        flex-direction: column;
      }
    }

    @include mq(l) {
      @include grid-item(4, -3);
    }

    &--two-columns {
      @include mq(l) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--three-columns {
      @include mq(l) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
