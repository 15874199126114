* {
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
}

html {
  text-rendering: optimizespeed; // Optimize text rendering speed
  text-size-adjust: 100%; // Prevents iOS text size adjust after orientation change, without disabling
}

body {
  -webkit-font-smoothing: antialiased; // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/
  -moz-osx-font-smoothing: grayscale; // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/
}
