.m-mixed-copy {
  position: relative;

  @include font-copy;

  h2,
  h3,
  p,
  ul,
  ol {
    margin-top: 2em;
  }

  h2 {
    @include font-headline-medium(true);
  }

  h3 {
    @include font-headline-small(true);
  }

  p,
  ul,
  ol {
    @include font-copy;
  }

  small {
    @include font-copy-small;
  }

  ul,
  ol {
    @include allowed-children((li));

    margin-left: calc(20px + 12px);

    @include mq(m) {
      letter-spacing: -0.1px;
      margin-left: calc(20px + 18px);
    }

    li {
      list-style: inherit;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;

    @include mq(m) {
      line-height: get-baseline(5);
    }
  }

  strong {
    font-weight: get-weight("bold");
  }

  em {
    font-style: italic;
  }

  a {
    color: get-color("magenta");
    text-decoration: underline;

    @include dont-break-out;

    &:hover,
    &:focus {
      color: get-color("magenta");
    }

    em &:hover,
    em &:focus {
      font-style: normal;
    }

    // External links
    &[href^="http://"]::after,
    &[href^="https://"]::after
    {
      content: "";

      // telekom-navigation-external-link-default.svg
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23e20074' fill-rule='evenodd' d='m12.65 3 1.5 1.5H4.5V18c0 .8.576 1.423 1.352 1.493L6 19.5h12c.8 0 1.423-.576 1.493-1.352L19.5 18V9.85l1.5 1.5V18a3.01 3.01 0 0 1-2.824 2.995L18 21H6a3.01 3.01 0 0 1-2.995-2.824L3 18V3h9.65ZM22.7 1.35l-1.65 7.9-2.6-2.6-7.55 7.55c-.15.15-.35.2-.55.2-.2 0-.4-.05-.55-.2a.722.722 0 0 1-.074-.965l.074-.085 7.55-7.55-2.6-2.6 7.95-1.65Z'/%3E%3C/svg%3E"); // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 1em;
      margin-left: get-baseline(0.5);
      vertical-align: middle;
      width: 1em;
    }

    &[href^="https://www.magentamusik.de"]::after,
    &[href^="https://staging.magentamusik.de"]::after
    {
      display: none;
    }
  }

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .o-content-blocks__block--text:not(
      .o-content-blocks__block--text + .o-content-blocks__block--text
    )
    &
    *:first-child {
    margin-top: 0;
  }

  .share {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cg%3E%3Crect fill='none' height='24' width='24'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M16,5l-1.42,1.42l-1.59-1.59V16h-1.98V4.83L9.42,6.42L8,5l4-4L16,5z M20,10v11c0,1.1-0.9,2-2,2H6c-1.11,0-2-0.9-2-2V10 c0-1.11,0.89-2,2-2h3v2H6v11h12V10h-3V8h3C19.1,8,20,8.89,20,10z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: 20px;
    position: relative;
    padding-right: 22px;
    margin-right: 3px;
  }

  &--has-toggle {
    padding-bottom: get-baseline(5);
    max-height: get-baseline(30);
    overflow: hidden;

    *:first-child {
      margin-top: 0;
    }
  }

  &--has-toggle.unfolded {
    max-height: none;
    overflow: initial;
  }

  &__toggle {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    appearance: none;
    border: none;
    padding: get-baseline(14) 0 0;
    background: none;
    outline: none;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      var(--theme-background) 60%
    );

    &:hover,
    &:focus {
      color: get-color("magenta");
    }

    svg {
      width: get-baseline(2);
      height: get-baseline(2);
      margin-left: get-baseline(1);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__show-less {
    display: none;
  }

  &.unfolded {
    .m-mixed-copy__show-more {
      display: none;
    }

    .m-mixed-copy__show-less {
      display: block;
    }

    .m-mixed-copy__toggle {
      background-image: none;
    }

    .m-mixed-copy__toggle svg {
      transform: rotate(180deg);
    }
  }
}
