.o-header {
  position: sticky;
  top: 0;
  transition: padding-bottom 0.8s ease, transform 0.25s ease;
  z-index: 1004; // The Video Players control elements have a z-index of 1002, the full-sizes video player has a z-index value of 1100

  &--is-hidden {
    transform: translateY(calc(-1 * var(--mm-header-height)));
  }

  &--persist-search {
    padding-bottom: var(--mm-search-bar-height);
  }

  &__content {
    background-image: linear-gradient(get-color("grey-dark"), transparent);
  }

  &__shape-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    height: 777px;
    pointer-events: none;
    overflow: hidden;

    .o-nav--is-active & {
      pointer-events: auto;
    }
  }

  &__shape {
    --offset-x: 518px;

    position: absolute;
    top: -7px;
    width: 2996px;
    left: 50%;
    transform:
      translateX(
        calc(-50% + min(#{$maxWidth*0.5}, 50vw) - var(--offset-x))
      );

    @include mq(m) {
      --offset-x: 749px;

      top: 24px;
    }

    svg {
      display: block;
      overflow: visible;
      fill: get-color("magenta");
      pointer-events: none;
    }

    &::before,
    &::after {
      background-color: get-color("magenta");
      content: "";
      position: absolute;
      width: 200vw;
      top: 0;
      height: var(--mm-header-height);

      @include mq(m) {
        top: -24px;
      }
    }

    &::before {
      left: calc(-50vw + 1px);
    }

    &::after {
      right: calc(-50vw + 1px);
    }
  }

  &__wrapper {
    @include grid-container;

    position: relative;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    background: get-color("magenta");

    @include mq(l) {
      background: transparent;
    }
  }

  &__inner {
    @include grid-item(2, -2);

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    height: var(--mm-header-height);
  }

  &__brand-link {
    justify-self: flex-start;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__brand-logo {
    color: get-color("white");
    fill: get-color("white");
    height: 26px;
    width: auto;

    @include mq(m) {
      height: 36px;
    }
  }

  &__logo {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    justify-self: center;

    @include mq(m) {
      top: 22px;
    }

    a {
      display: flex;
      text-decoration: none;
    }
  }

  &__trigger {
    display: flex;
    justify-self: end;
  }

  &__button {
    transition: none;

    &:not(:first-of-type) {
      margin-left: get-baseline(1);
    }

    &:focus {
      color: get-color("white");
    }

    &:hover {
      color: get-color("white-dark");
    }
  }

  &__search-bar {
    left: 0;
    position: absolute;
    top: var(--mm-header-height);
    width: 100%;
    z-index: 1;
  }

  &__meta {
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    // .o-nav--is-active & {
    //   opacity: 0;
    // }
  }

  &__tags {
    display: flex;
    overflow: auto;
    padding: get-baseline(4) get-baseline(2);
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: get-baseline(1);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
