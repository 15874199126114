.o-anchor-links {
  position: sticky;
  top: var(--mm-header-height);
  width: 100%;
  height: var(--mm-header-height);
  background: get-color("magenta");
  overflow: hidden;
  z-index: 1002;
  margin-bottom: get-baseline(11);
  transition: top 0.25s ease;
  display: flex;
  align-items: center;

  &__wrapper {
    @include grid-container;

    margin: 0 auto;
  }

  .o-header--is-hidden ~ main & {
    top: 0;
  }

  ul {
    display: flex;
    align-items: center;
    gap: get-baseline(3);

    @include grid-item(2, -2);

    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq(l) {
      gap: get-baseline(5);

      @include grid-item(3, -3);
    }

    li {
      display: flex;
      flex-shrink: 0;
      scroll-snap-align: start;
    }
  }

  &__link {
    text-decoration: none;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include font-headline-small;

    // prevent layout shift on font-weight hover
    &::before {
      content: attr(data-text);
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: get-weight("bold");

      @media speech {
        display: none;
      }
    }

    &.active {
      font-weight: get-weight("bold");
    }

    @media (pointer: fine) {
      &:hover {
        font-weight: get-weight("bold");
      }
    }
  }
}
