.o-stage {
  @include grid-container;

  overflow: visible;
  position: relative;
  padding-top: get-baseline(3);
  margin-top: get-baseline(-3);

  &__wrapper {
    @include grid-item(2, -2);

    // Tiny Slider
    .tns-outer {
      display: flex;
      flex-direction: column-reverse;

      // Tiny Slider Dots
      .tns-nav {
        display: flex;
        justify-content: center;
        transform: translateY(get-baseline(-8));
        width: 100%;
        z-index: 1;

        > button {
          position: relative;
          appearance: none;
          background: none;
          border: 0;
          border-radius: 50%;
          width: get-baseline(7);
          height: get-baseline(7);
          padding: 0;
          margin: 0;
          transition: 0.1s ease all;

          &:focus {
            background-color: rgba(get-color("white"), 0.05);
          }

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            border-radius: 50%;
            background-color: rgba(get-color("white"), 0.3);
            height: get-baseline(2);
            width: get-baseline(2);
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &.tns-nav-active::before {
            background-color: get-color("white");
          }
        }
      }

      .tns-ovh {
        overflow: visible;
      }
    }

    .m-teaser__image-wrapper img {
      width: 100%;
      position: relative;
      display: block;
      top: auto;
      left: auto;
      object-fit: fill;
      height: auto;
    }
  }

  &__slideshow {
    position: relative;
    opacity: 0;
    transition: opacity 0.1s ease, transform 0.8s ease-in-out;

    &.initialized {
      opacity: 1;
    }

    &-slide:not(.tns-slide-active) {
      pointer-events: none;
    }

    &-slide .m-teaser__image-wrapper {
      height: 0;
      position: relative;

      // Teaser has different aspect ratios depending on screen size
      padding-bottom: 100%;

      @include mq(640px) {
        padding-bottom: calc(100% / (16 / 7));
      }

      @include mq(1280px) {
        padding-bottom: calc(100% / (16 / 5));
      }

      picture {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    &-controls {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease;
      width: 100%;

      [data-whatinput="mouse"] &,
      [data-whatinput="touch"] & {
        outline: 0;
      }

      bottom: get-baseline(8);

      @include mq(m) {
        bottom: get-baseline(12);
      }

      @include mq(l) {
        bottom: get-baseline(14);
      }
    }

    &-prev-button,
    &-next-button {
      position: absolute;
      top: 50%;
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &-prev-button {
      left: 0;
      transform: translate(-100%, -50%);

      &:not([disabled]) {
        pointer-events: all;
      }

      &[disabled] {
        opacity: 0;
      }
    }

    &-next-button {
      right: 0;
      transform: translate(100%, -50%);

      &:not([disabled]) {
        pointer-events: all;
      }

      &[disabled] {
        opacity: 0;
      }
    }
  }

  [data-whatintent="mouse"] &:hover &__slideshow-prev-button {
    transform: translate(get-baseline(5), -50%);
  }

  [data-whatintent="mouse"] &:hover &__slideshow-next-button {
    transform: translate(get-baseline(-5), -50%);
  }

  [data-whatintent="mouse"] &:hover &__slideshow-prev-button:hover {
    transform: translate(calc(#{get-baseline(5)} - 2px), -50%);
  }

  [data-whatintent="mouse"] &:hover &__slideshow-next-button:hover {
    transform: translate(calc(#{get-baseline(-5)} + 2px), -50%);
  }

  [data-whatintent="mouse"] &:hover &__slideshow-controls {
    opacity: 1;
  }
}
