.t-offline-page {
  position: relative;

  &__wrapper {
    @include grid-container;

    position: relative;
  }

  &__stage {
    margin-top: get-baseline(12);
    text-align: center;
    padding-left: get-baseline(2);
    padding-right: get-baseline(2);

    @include grid-item(1);

    @include mq(m) {
      padding: 0;

      @include grid-item(5, -5, "desktop");
    }

    @include mq(m) {
      margin-top: get-baseline(24);
    }

    h1 {
      margin-bottom: get-baseline(4);
      font-weight: get-weight("extra");
    }

    svg {
      height: get-baseline(8);
      width: get-baseline(8);
      margin-bottom: get-baseline(4);
    }
  }
}
