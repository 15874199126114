.o-content-blocks {
  @include grid-container;

  position: relative;
  z-index: 1;

  $narrow: #{&}--narrow;

  &__block {
    $content-block: &;

    z-index: 2;

    &:last-child {
      margin-bottom: get-baseline(13);
    }

    &:first-of-type:not(
        .o-content-blocks__block--page-title,
        .o-content-blocks__block--icon-row
      ) {
      margin-top: 0;
    }

    &--collection-tabs,
    &--teaser-slider,
    &--artists-overview,
    &--music-finder,
    &--teaser-grid,
    &--ticket-countdown-teaser,
    &--gallery,
    &--hero-teaser,
    &--product-teaser {
      @include grid-item(1);
    }

    &--line-up,
    &--ad-banner,
    &--social-wall-cta,
    &--social-list-teaser,
    &--pwa-teaser,
    &--video-banner,
    &--newsletter-teaser,
    &--ticket-teasers,
    &--livestream-teaser,
    &--social-newsletter-row {
      @include grid-item(2, -2);
    }

    &--page-intro {
      @include grid-item(1);

      margin: 0 0 get-baseline(10) 0;
      z-index: 1;

      .m-page-intro--no-image {
        margin-top: get-baseline(12);

        @include mq(m) {
          margin-top: get-baseline(18);
        }
      }
    }

    &--social-wall {
      @include grid-item(1);

      margin-top: get-baseline(6);
      margin-bottom: get-baseline(6);
    }

    &--content-teaser {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(2, -2, "desktop");
      }

      @include mq(l) {
        @include grid-item(3, -5, "desktop");

        #{$narrow} & {
          @include grid-item(4, -4, "desktop");
        }
      }
    }

    &--video,
    &--page-title,
    &--notify,
    &--form,
    &--partners-list,
    &--image {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(3, -3, "desktop");

        #{$narrow} & {
          @include grid-item(4, -4, "desktop");
        }
      }

      @include mq(l) {
        #{$narrow} & {
          @include grid-item(5, -5, "desktop");
        }
      }
    }

    &--icon-row {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(2, -2, "desktop");
      }
    }

    &--ticket-faq {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(3, -3, "desktop");
      }

      @include mq(l) {
        @include grid-item(4, -4, "desktop");
      }
    }

    &--partners-list {
      margin: get-baseline(10) 0;
    }

    &--anchor {
      @include grid-item(1, -1);
    }

    &--accordion,
    &--text,
    &--video-story {
      @include grid-item(2, -2);

      @include mq(m) {
        @include grid-item(3, -3);
      }

      @include mq(l) {
        @include grid-item(4, -4, "desktop");
      }

      #{$narrow} & {
        @include mq(m) {
          @include grid-item(4, -4, "desktop");
        }

        @include mq(l) {
          @include grid-item(5, -5, "desktop");
        }
      }

      .m-mixed-copy {
        @include grid-container(12);

        @include mq(m) {
          @include grid-container(10);
        }

        @include mq(l) {
          @include grid-container(8);
        }

        p {
          grid-column: 1 / -1;

          @include mq(l) {
            grid-column: 1 / -2;
          }
        }

        h2,
        h3,
        h4,
        ul,
        ol {
          grid-column: 1 / -1;
        }
      }

      #{$narrow} & .m-mixed-copy {
        display: block;

        h2 {
          @include font-headline-small(true);
        }
      }

      .m-mixed-copy p:last-child,
      .m-mixed-copy ul:last-child,
      .m-mixed-copy ol:last-child {
        margin-bottom: 0;
      }
    }

    &--video-story {
      @include mq(l) {
        @include grid-item(3, -2, "desktop");

        #{$narrow} & {
          @include grid-item(5, -5, "desktop");
        }
      }
    }

    &--text,
    &--image,
    &--notify,
    &--video-story {
      margin: get-baseline(3) 0 0;
    }

    &--form {
      margin: get-baseline(4) 0;
    }

    &--page-title {
      margin: get-baseline(20) 0 0;
    }

    &--teaser-lane {
      @include grid-item(1);
    }

    &--accordion,
    &--line-up,
    &--collection-tabs,
    &--teaser-lane,
    &--artists-overview,
    &--music-finder,
    &--teaser-grid,
    &--pwa-teaser,
    &--ad-banner,
    &--teaser-slider,
    &--gallery,
    &--video-banner,
    &--content-teaser,
    &--icon-row,
    &--social-list-teaser,
    &--social-wall-cta,
    &--video,
    &--newsletter-teaser,
    &--livestream-teaser,
    &--ticket-countdown-teaser,
    &--ticket-teasers,
    &--hero-teaser,
    &--social-newsletter-row {
      margin: get-baseline(11) 0;

      @include mq(m) {
        margin: get-baseline(14) 0;
      }

      + #{$content-block} {
        &--accordion,
        &--line-up,
        &--collection-tabs,
        &--teaser-lane,
        &--artists-overview,
        &--music-finder,
        &--teaser-grid,
        &--pwa-teaser,
        &--ad-banner,
        &--teaser-slider,
        &--gallery,
        &--video-banner,
        &--content-teaser,
        &--icon-row,
        &--social-list-teaser,
        &--ticket-countdown-teaser,
        &--social-wall-cta,
        &--video,
        &--newsletter-teaser,
        &--livestream-teaser,
        &--hero-teaser,
        &--social-newsletter-row {
          margin-top: 0;
        }
      }
    }

    &--pwa-teaser {
      body[data-display-mode="standalone"] &,
      body[data-pwa-installed="true"] &,
      body[data-pwa-supported="false"] & {
        display: none;
      }
      // stylelint-disable
      @media (display-mode: standalone) {
        display: none;
      }
      // stylelint-enable
    }
  }
}
