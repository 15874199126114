.o-tag-stage {
  @include grid-container;

  grid-row-gap: get-baseline(2);
  margin-bottom: get-baseline(10);

  &__topline {
    @include grid-item(2, -2);

    @include mq(m) {
      margin-bottom: get-baseline(3);
    }
  }

  &__headline {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -3);
    }

    @include mq(l) {
      @include grid-item(3, -5);
    }
  }

  &__intro {
    @include grid-item(2, -2);

    @include mq(m) {
      @include grid-item(3, -3);
    }

    @include mq(l) {
      @include grid-item(3, -7);
    }
  }
}
