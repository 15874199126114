@keyframes navigation-fadein {
  0% {
    display: flex;
    opacity: 0;
    visibility: visible;
  }

  99% {
    display: flex;
    opacity: 1;
    visibility: visible;
  }

  100% {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes navigation-fadeout {
  0% {
    display: flex;
    opacity: 1;
  }

  99% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

.o-nav {
  left: 0;
  justify-content: flex-end;
  overflow-y: auto;
  padding: get-baseline(14) 0 get-baseline(10);
  position: absolute;
  width: 100%;
  top: 0;
  transition: all 0.25s ease;
  z-index: 3;
  visibility: hidden;
  animation: navigation-fadeout 0.2s ease-in-out forwards;
  max-height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @include mq(l) {
    padding-top: get-baseline(20);
    padding-bottom: 0;
  }

  &--is-active & {
    animation: navigation-fadein 0.2s ease-in-out forwards;
  }

  &__wrapper {
    margin: 0 auto;
    overflow: auto;

    @include grid-container;
  }

  ul {
    @include grid-item(2, -2);

    width: 100%;
    color: get-color("white");
    text-align: right;

    a {
      text-decoration: none;
      display: inline-block;

      &:focus,
      &:hover {
        color: get-color("white-dark");
      }

      span {
        display: block;
        width: 100%;
      }
    }
  }

  &__main-links {
    li:not(:first-of-type) {
      margin-top: get-baseline(4);
    }
  }

  &__meta-links {
    margin-top: get-baseline(6);

    li:not(:first-of-type) {
      margin-top: get-baseline(1);
    }
  }
}

.o-header--is-sticky {
  .o-nav {
    height:
      calc(
        100vh + #{get-baseline(7)}
      ); // Add value of attribute top in src/components/organisms/header/_header.scss to maintain full height

    @include mq(m) {
      height:
        calc(
          100vh + #{get-baseline(11)}
        ); // Add value of attribute top in src/components/organisms/header/_header.scss to maintain full height
    }
  }
}

.o-header--is-hidden {
  .o-nav {
    height:
      calc(
        100vh + #{get-baseline(18)}
      ); // Add value of attribute top in src/components/organisms/header/_header.scss to maintain full height

    @include mq(m) {
      height:
        calc(
          100vh + #{get-baseline(22)}
        ); // Add value of attribute top in src/components/organisms/header/_header.scss to maintain full height
    }
  }
}
