.a-logo {
  font-family: $font-magenta-musik;
  font-size: get-baseline(3);
  line-height: get-baseline(3);
  font-weight: get-weight("regular");
  text-decoration: none;
  display: inline-block;
  pointer-events: none;
  white-space: nowrap;
  color: get-color("magenta");

  @include mq(m) {
    font-size: 30px;
    line-height: get-baseline(5);
  }

  strong {
    font-weight: get-weight("extra");
  }

  &--color-white {
    color: get-color("white");
  }

  &--size-medium {
    font-size: get-baseline(3);
    line-height: get-baseline(3);

    @include mq(m) {
      font-size: get-baseline(5);
      line-height: get-baseline(5);
    }

    @include mq(l) {
      font-size: get-baseline(6);
      line-height: get-baseline(6);
    }
  }

  &--size-large {
    font-size: get-baseline(5);
    line-height: get-baseline(5);

    @include mq(m) {
      font-size: get-baseline(7);
      line-height: get-baseline(7);
    }

    @include mq(l) {
      font-size: get-baseline(8);
      line-height: get-baseline(8);
    }
  }
}
