.a-svg-sprite {
  background-color: transparent;
  fill: currentcolor;
  height: get-baseline(17);
  pointer-events: none;
  width: get-baseline(17);

  &[class*="--social-"] {
    height: get-baseline(4);
    width: get-baseline(4);
  }

  &[class*="--inline-"] {
    height: get-baseline(3);
    width: get-baseline(3);
  }
}
