.m-store-buttons {
  display: flex;

  &__button {
    display: block;
    margin-right: get-baseline(2);
    transition: transform 250ms ease-out;

    &:hover {
      transform: scale(1.05);
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      display: block;
      height: get-baseline(4);
      width: auto;

      @include mq(m) {
        height: get-baseline(5);
      }
    }
  }
}
