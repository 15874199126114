.m-partners-list {
  &__headline {
    margin-bottom: get-baseline(6);
  }

  &__partners {
    @include grid-container(3);

    @include mq(m) {
      @include grid-container(6);
    }

    &--one-column {
      @include grid-container(6);

      @include mq(m) {
        @include grid-container(12);
      }

      @include mq(l) {
        @include grid-container(10);
      }
    }
  }

  &__partner {
    width: 100%;
  }

  &__partner-logo {
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
