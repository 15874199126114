.m-content-video {
  $module: &;

  position: relative;
  z-index: 9;
  color: get-color("white");

  @media print {
    display: none;
  }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;

    &-image {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__playbutton-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &__ratio {
    height: 0;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-top: 56.26%;

    > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &__embed-container {
    z-index: 1;
  }

  &__cookie-consent {
    background: get-color("grey-dark");
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    text-align: center;
    padding: get-baseline(5);

    .m-mixed-copy {
      max-width: 400px;
    }

    button {
      margin-top: get-baseline(1);
    }
  }

  video,
  iframe {
    width: 100%;
    height: 100%;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster,
  &--is-consent &__button,
  &--is-consent &__poster,
  &--is-playing &__cookie-consent {
    display: none;
  }

  &--is-consent &__cookie-consent {
    display: flex;
  }

  &__caption {
    // @include text-type-copy;

    margin-top: get-baseline(1);
  }
}
